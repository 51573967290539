import ActiveLink from '@/components/common/ActiveLink'
import PageLoader from '@/components/common/PageLoader'
import { HOME } from '@/router/allUrls'
import { RESET_CHECK_PRICE_DATA, USER_LOGOUT } from '@/store/constants/actionTypes'
import { m } from 'framer-motion'
import { useRouter } from 'next/router'
import React from 'react'
import { connect } from 'react-redux'
import MenuItemDashboard from './MenuItemDashboard'

const variants = {
  open: { opacity: 1, height: 'auto' },
  closed: { opacity: 0, height: 0 }
}

const MenuDashboard = function (props) {
  const router = useRouter()

  let menuItems = props.menuItems.map((item, key) => {
    return <MenuItemDashboard key={key} item={item} />
  })

  const logout = async () => {
    await props.onLogout()
  }

  const removeCachedCheckPriceData = e => {
    e.preventDefault()
    props.onResetCheckPriceData()
    router.push(`${HOME}?new_order=true`).then()
  }

  // get Auth Menu
  const getAuthItems = () => {
    const authInfo = props.Auth.authInfo

    return (
      <>
        <li className='profile text-center'>
          {authInfo ? (
            <>
              <span className='account-name'>
                Hello <span className='text-secondary font-semibold'>{authInfo.first_name}</span>
              </span>
              <span className='account-number'>
                Account: <span className='text-secondary font-semibold'>{authInfo.username}</span>
              </span>
            </>
          ) : (
            <div className={'w-[170px]'}>
              <PageLoader />
            </div>
          )}
        </li>
        <li className='auth-btns'>
          <button type='button' className='btn btn-primary logout' onClick={logout}>
            Logout
          </button>
          <ActiveLink href={HOME} onClick={removeCachedCheckPriceData} className='btn btn-primary new-order'>
            Start New Order
          </ActiveLink>
        </li>
      </>
    )
  }

  return (
    <React.Fragment>
      {/* animate={props.getMenuStatus() ? "open" : "closed"} */}
      <m.ul className='main-menu user-main-menu gap-2 xl:gap-10' animate='open' variants={variants}>
        {menuItems}
      </m.ul>

      <m.ul className='main-menu user-main-menu gap-2 xl:gap-10' animate='open' variants={variants}>
        {getAuthItems()}
      </m.ul>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    Auth: state.authReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: USER_LOGOUT }),
  onResetCheckPriceData: () => dispatch({ type: RESET_CHECK_PRICE_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuDashboard)
