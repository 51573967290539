import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faList, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import _, { has } from 'lodash'
import MenuItem from './MenuItem'
import ActiveLink from '@/components/common/ActiveLink'
import { assets } from '@/helpers/common'

library.add(faList, faTimes, faChevronDown, faChevronUp)

/*const variants = {
  open: { opacity: 1, height: 'auto' },
  closed: { opacity: 0, height: 0 }
}
const variants_li = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  }
}*/

const MenuItemDashboard = function (props) {
  const [subMenuStatus, setsubMenuStatus] = useState(false)
  // const [width, height] = WindowSize()

  // change Sub Menu Status
  const changeSubMenuStatus = () => {
    setsubMenuStatus(!subMenuStatus)
  }

  // get Sub Menu Status
  const getSubMenuStatus = () => {
    return window.innerWidth < 992 ? subMenuStatus : true
  }

  // collece sub menu
  let submenuItems = false
  if (!_.isEmpty(props.item.childrens) && props.item.childrens.length > 0) {
    submenuItems = props.item.childrens.map((item, key) => {
      return <MenuItem key={key} item={item} changeMenuStatus={props.changeMenuStatus} />
    })
  }

  function getMenuIconTitle(item) {
    let linkDisabled = has(item, 'disabled') && item.disabled
    if (linkDisabled) {
      return (
        <a
          // to={ item.link }
          disabled={true}
          className={item.type === 'btn' ? 'btn btn-primary' : ''}
        >
          <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
          <span className='icon'>
            <img src={assets(item.icon)} alt={''} />
          </span>
        </a>
      )
    } else {
      return (
        <ActiveLink
          href={item.link}
          onClick={props.changeMenuStatus}
          className={`${item.type === 'btn' ? 'btn btn-primary active' : ''}`}
        >
          <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
          <span className='icon'>
            <img src={assets(item.icon)} alt={''} />
          </span>
        </ActiveLink>
      )
    }
  }

  let className = props.item.className ? props.item.className : ''

  return (
    <li className={className}>
      {getMenuIconTitle(props.item)}
      {submenuItems ? (
        <span className='submenu-togle' onClick={changeSubMenuStatus}>
          {getSubMenuStatus() ? (
            <span className='submenu-close'>
              <FontAwesomeIcon icon={['fas', 'chevron-up']} />
            </span>
          ) : (
            <span className='submenu-open'>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} />
            </span>
          )}
        </span>
      ) : (
        ''
      )}
      {submenuItems ? <ul className='submenu'>{submenuItems}</ul> : ''}
    </li>
  )
}

export default MenuItemDashboard
