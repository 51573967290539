import { put } from 'redux-saga/effects'
import * as types from '@/store/constants/actionTypes'
import axios from 'axios'
import Configs from '@/configs'

// GET captcha info
export function* getCaptchaSaga(action) {
  try {
    yield put({ type: types.GET_CAPTCHA_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .get('captcha/flat?' + action.data)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200) {
      yield put({ type: types.GET_CAPTCHA_SUCCESS, data: result.data })
    } else {
      // yield put({ type: types.GET_EDIT_ORDER_FAILED, data: result.response.data });
    }
    // yield put({ type: types.GET_EDIT_ORDER_END });
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// submit contact form
export function* submitContactFormSaga(action) {
  try {
    yield put({ type: types.SUBMIT_CONTACT_FORM_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .post('contact', action.data)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200) {
      yield put({ type: types.SUBMIT_CONTACT_FORM_SUCCESS, data: result.data })
    } else {
      yield put({ type: types.SUBMIT_CONTACT_FORM_FAILED, data: result.response.data })
    }
    // yield put({ type: types.SUBMIT_CONTACT_FORM_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
