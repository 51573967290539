import * as actionTypes from '../constants/actionTypes'

const initialState = {
  checkAuth: true,
  auth: false,

  setError: false,
  success: false,
  loading: false,
  errors: {},

  loginLoading: false,
  loginSuccess: false,
  loginSetError: false,
  loginErrors: {},

  resetLoading: false,
  resetSuccess: false,
  resetSetError: false,
  resetErrors: {},

  changePassLoading: false,
  changePassSuccess: false,
  changePassSetError: false,
  changePassShowError: false,
  changePassErrors: {},

  authInfo: false,
  resetMessage: false,
  changePassMessage: false
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // register start
    case actionTypes.USER_REGISTER_START:
      return {
        ...state,
        setError: false,
        success: false,
        loading: true,
        errors: false
      }
    case actionTypes.USER_REGISTER_SUCCESS:
      let resData = action.data

      return {
        ...state,
        setError: false,
        success: true,
        loading: false,
        auth: true,
        authInfo: resData.user
      }
    case actionTypes.USER_REGISTER_FAILED:
      return {
        ...state,
        errors: action.data,
        setError: true,
        loading: false
      }
    case actionTypes.USER_REGISTER_END:
      return {
        ...state,
        errors: {},
        setError: false,
        success: false,
        loading: false
      }
    // login start
    case actionTypes.USER_LOGIN_START:
      return {
        ...state,
        loginSetError: false,
        loginSuccess: false,
        loginLoading: true,
        loginErrors: false
      }
    case actionTypes.USER_LOGIN_SUCCESS:
      let loginData = action.data

      return {
        ...state,
        loginSetError: false,
        loginSuccess: true,
        loginLoading: false,
        auth: true,
        authInfo: loginData.user
      }
    case actionTypes.USER_LOGIN_FAILED:
      return {
        ...state,
        loginErrors: action.data,
        loginSetError: true,
        loginLoading: false
      }
    case actionTypes.USER_LOGIN_END:
      return {
        ...state,
        errors: {},
        loginSetError: false,
        loginSuccess: false,
        loginLoading: false
      }
    // set auth
    case actionTypes.SET_AUTH_USER:
      return {
        ...state,
        checkAuth: false,
        auth: true,
        authInfo: action.data
      }
    case actionTypes.AUTH_FAILED:
      return {
        ...state,
        checkAuth: false,
        auth: false,
        authInfo: false
      }
    // logout actions
    case actionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        auth: false,
        authInfo: false
      }
    case actionTypes.USER_LOGOUT_FAILED:
      return {
        ...state,
        auth: false,
        authInfo: false
      }
    // reset password start
    case actionTypes.RESET_PASSWORD_INIT:
      return {
        ...state,
        resetErrors: false,
        resetSuccess: false,
        resetLoading: false,
        resetMessage: false
      }
    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        resetSetError: false,
        resetSuccess: false,
        resetLoading: true
      }
    case actionTypes.RESET_PASSWORD_SUCCESS:
      let resetData = action.data

      return {
        ...state,
        resetSetError: false,
        resetSuccess: true,
        resetLoading: false,
        resetMessage: resetData.message
      }
    case actionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetErrors: action.data,
        resetSetError: true,
        resetLoading: false
      }
    case actionTypes.RESET_PASSWORD_END:
      return {
        ...state,
        resetErrors: false,
        resetSetError: false,
        resetSuccess: false,
        resetLoading: false
      }
    // Change password start
    case actionTypes.CHANGE_PASSWORD_INIT:
      return {
        ...state,
        changePassErrors: false,
        changePassSuccess: false,
        changePassLoading: false,
        changePassMessage: false
      }
    case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassSetError: false,
        changePassShowError: false,
        changePassSuccess: false,
        changePassLoading: true
      }
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      let changePassData = action.data

      return {
        ...state,
        changePassSetError: false,
        changePassShowError: false,
        changePassSuccess: true,
        changePassLoading: false,
        changePassMessage: changePassData.message
      }
    case actionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changePassErrors: action.data,
        changePassSetError: true,
        changePassShowError: true,
        changePassLoading: false
      }
    case actionTypes.CHANGE_PASSWORD_END:
      return {
        ...state,
        changePassSetError: false,
        // changePassSuccess: false,
        changePassLoading: false
      }
    default:
      return state
  }
}

export default authReducer
