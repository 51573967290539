import { combineReducers } from 'redux'
import authReducer from '@/store/reducers/authReducer'
import orderReducer from '@/store/reducers/orderReducer'
import globalReducer from '@/store/reducers/globalReducer'
import myStorageReducer from '@/store/reducers/myStorageReducer'
import userPanelReducer from '@/store/reducers/userPanalReducer'

/* Slices */
import modalSlice from '@/store/reducers/modalSlice'
import customDocumentSlice from '@/store/reducers/customDocumentSlice'

const rootReducer = combineReducers({
  authReducer: authReducer,
  orderReducer: orderReducer,
  globalReducer: globalReducer,
  myStorageReducer: myStorageReducer,
  userPanelReducer: userPanelReducer,

  modalSlice: modalSlice,
  customDocumentSlice: customDocumentSlice
})

export type RootReducerProps = ReturnType<typeof rootReducer>

export default rootReducer
