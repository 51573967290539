import { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '@/store/constants/actionTypes'

const AuthCheck = ({ children, ...props }) => {
  useEffect(() => {
    if (props.authReducer.auth === false) {
      props.setCurrentUserIfExits()
    }
  }, [])

  // if (props.authReducer.checkAuth) {
  //   return (
  //     <PageLoader/>
  //   )
  // }

  return children
}

const mapDispatchToProps = dispatch => ({
  setCurrentUserIfExits: () => dispatch({ type: actionTypes.CHECK_AUTH_USER })
})

const mapStateToProps = state => ({
  authReducer: state.authReducer
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthCheck)
