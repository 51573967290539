import React from 'react'
import { cn } from '@/lib/utils'
import { FooterActiveLink } from '@/layouts/v1/footer'

interface FooterLinksProps {
  title?: string
  items: { id: number; url: string; label: string; className?: string; target?: '_self' | '_blank' }[]
}

export function FooterLinkItem({ title, items }: FooterLinksProps) {
  return (
    <>
      <div>
        {!!title && <h2 className={'text-lg text-gray-dark-slate lg:text-xl font-bold mb-5'}>{title}</h2>}
        <ul className={'space-y-4'}>
          {items?.length > 0 &&
            items?.map(item => (
              <li key={item.id}>
                <FooterActiveLink href={item?.url} target={item?.target ?? '_self'} className={cn('', item?.className)}>
                  {item?.label}
                </FooterActiveLink>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}
