import React from 'react'

import { cn } from '@/lib/utils'
import { FooterMenuLinks, FooterSocial } from '@/layouts/v1/footer'

export default function Footer() {
  return (
    <footer className={cn('sas-footer-wr bg-[#CDF0FF]')}>
      <section className={cn('')}>
        <div className={'container'}>
          <div className={'section-spacing space-y-8 xl:space-y-11'}>
            <div className={cn('flex flex-col xl:flex-row justify-between gap-4')}>
              <FooterMenuLinks />
            </div>

            <div className='lg:hidden xl:block'>
              <FooterSocial />
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}
