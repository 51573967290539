import React from 'react'
import { cn } from '@/lib/utils'
import { FOOTER } from '@/layouts/v1/footer/constants'
import { FooterLinkItem, FooterLinksAccordion, FooterSocial } from '@/layouts/v1/footer'

export function FooterMenuLinks() {
  const [expanded, setExpanded] = React.useState<string | boolean>('panel-0')

  return (
    <>
      {FOOTER?.items?.length > 0 &&
        FOOTER?.items?.map(menu => (
          <div key={menu?.id} className={cn('')}>
            <div>
              <div className='hidden xl:block'>
                <FooterLinkItem title={menu?.title || ''} items={menu?.children || []} />
              </div>
              <div className='xl:hidden'>
                <FooterLinksAccordion
                  keyName={`panel-${menu?.id}`}
                  title={menu?.title || ''}
                  items={menu?.children || []}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </div>
            </div>
          </div>
        ))}
      <div className={'hidden items-end lg:flex xl:hidden'}>
        <FooterSocial />
      </div>
    </>
  )
}
