import React from 'react'
import { assets } from '@/helpers/common'

function PageLoader() {
  return (
    <div className='page-loader' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={assets('images/loading.svg')} alt={''} />
    </div>
  )
}

export default PageLoader
