import * as actionTypes from '@/store/constants/actionTypes'
import { getCaptchaSaga, submitContactFormSaga } from '@/store/sagas/public'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  bookNowSaga,
  fedexStoreList,
  get_label_delivery,
  getAllCountriesSaga,
  getAllPaymentStatesSaga,
  getAllReceiverStatesSaga,
  getAllStatesSaga,
  getAllWarehouseSaga,
  getEditOrderSaga,
  getInsuranceInfo,
  getOrderInfoSaga,
  getPriceListSaga,
  getSenderReceiverSaga,
  getTrackingInfoSaga,
  promocode_apply,
  reset_insurance,
  sendPayment_req,
  setSenderOrigininfo,
  submit_insurance,
  submit_order,
  update_label_delivery,
  update_receiver
} from '@/store/sagas/order'

import {
  changePasswordSaga,
  checkAuthUserSaga,
  resetPasswordSaga,
  userLoginSaga,
  userLogOutSaga,
  userRegisterSaga
} from '@/store/sagas/auth'

import {
  getDownloadPrintLabel,
  getIncompleteOrders,
  getMyShipmentStatistic,
  getMyStorageStatistic,
  getOrderByStatus,
  getOrderDetails,
  getShipmentHistoryOrders,
  getUserOrderStatistic,
  putCancelOrder
} from '@/store/sagas/userPlan'

import {
  getMyStorageHistoryOrders,
  getMyStorageInProgressOrders,
  getMyStorageOrders,
  getMyStorageOrdersDetails,
  getShipAndPickupDetails
} from './myStorage'

export function* watchPublic() {
  yield all([
    takeLatest(actionTypes.GET_CAPTCHA, getCaptchaSaga),
    takeLatest(actionTypes.SUBMIT_CONTACT_FORM, submitContactFormSaga)
  ])
}
export function* watchOrder() {
  yield all([
    takeLatest(actionTypes.FETCH_COUNTRIES, getAllCountriesSaga),
    takeLatest(actionTypes.FETCH_WAREHOUSE, getAllWarehouseSaga),
    takeLatest(actionTypes.FETCH_STATES, getAllStatesSaga),
    takeLatest(actionTypes.PAYMENT_STATES, getAllPaymentStatesSaga),
    takeLatest(actionTypes.GET_PRICE_LIST, getPriceListSaga),
    takeLatest(actionTypes.GET_EDIT_ORDER, getEditOrderSaga),
    takeLatest(actionTypes.GET_ORDER_INFO, getOrderInfoSaga),
    takeLatest(actionTypes.GET_SENDER_ORIGIN_ADDR, getSenderReceiverSaga),
    takeLatest(actionTypes.SET_SENDER_ORIGIN, setSenderOrigininfo),
    takeLatest(actionTypes.SEND_PAYMENT_API_REQ, sendPayment_req),
    takeLatest(actionTypes.SUBMIT_ORDER, submit_order),
    takeLatest(actionTypes.GET_INSURANCE_INFO, getInsuranceInfo),
    takeLatest(actionTypes.SUBMIT_INSURANCE, submit_insurance),
    takeLatest(actionTypes.RESET_INSURANCE, reset_insurance),
    takeLatest(actionTypes.BOOK_NOW, bookNowSaga),
    takeLatest(actionTypes.FEDEX_STORE_LIST, fedexStoreList),
    takeLatest(actionTypes.PROMO_CODE, promocode_apply),
    takeLatest(actionTypes.GET_LABEL_INFO, get_label_delivery),
    takeLatest(actionTypes.SET_LABEL_INFO, update_label_delivery),
    takeLatest(actionTypes.RECEIVER_STATES, getAllReceiverStatesSaga),
    takeLatest(actionTypes.SET_RECEIVER, update_receiver),
    takeLatest(actionTypes.GET_TRACKING_INFO, getTrackingInfoSaga)
  ])
}

export function* watchAuth() {
  yield all([
    takeLatest(actionTypes.USER_REGISTER, userRegisterSaga),
    takeLatest(actionTypes.USER_LOGIN, userLoginSaga),
    takeLatest(actionTypes.USER_LOGOUT, userLogOutSaga),
    takeEvery(actionTypes.CHECK_AUTH_USER, checkAuthUserSaga),
    takeEvery(actionTypes.RESET_PASSWORD, resetPasswordSaga),
    takeEvery(actionTypes.CHANGE_PASSWORD, changePasswordSaga)
  ])
}

export function* watchUserPanel() {
  yield all([
    takeLatest(actionTypes.GET_USER_ORDER_STATISTIC, getUserOrderStatistic),
    takeLatest(actionTypes.GET_MY_SHIPMENT_ORDER_STATISTIC, getMyShipmentStatistic),
    takeLatest(actionTypes.GET_MY_STORAGE_ORDER_STATISTIC, getMyStorageStatistic),
    takeLatest(actionTypes.GET_INCOMPLETE_ORDER, getIncompleteOrders),
    takeLatest(actionTypes.GET_SS_ORDER_BY_STATUS, getOrderByStatus),
    takeLatest(actionTypes.GET_SHIPMENT_HISTORY_ORDERS, getShipmentHistoryOrders),
    takeLatest(actionTypes.GET_DOWNLOAD_PRINT_LABEL, getDownloadPrintLabel),
    takeLatest(actionTypes.GET_ORDER_DETAILS, getOrderDetails),
    takeLatest(actionTypes.PUT_CANCEL_ORDER, putCancelOrder)
  ])
}

export function* watchMyStorage() {
  yield all([
    takeLatest(actionTypes.GET_MY_STORAGE_ORDER, getMyStorageOrders),
    takeLatest(actionTypes.GET_MY_STORAGE_IN_PROGRESS_ORDER, getMyStorageInProgressOrders),
    takeLatest(actionTypes.GET_MY_STORAGE_ORDER_DETAILS, getMyStorageOrdersDetails),
    takeLatest(actionTypes.GET_SHIP_AND_PICKUP_ORDER_DETAILS, getShipAndPickupDetails),
    takeLatest(actionTypes.GET_MY_STORAGE_HISTORY_ORDERS, getMyStorageHistoryOrders)
  ])
}

export default function* rootSaga() {
  yield all([watchPublic(), watchOrder(), watchAuth(), watchUserPanel(), watchMyStorage()])
}
