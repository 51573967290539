import useMediaQuery from '@mui/material/useMediaQuery'

// To perform the server-side hydration, the hook needs to render twice.
// A first time with false, the value of the server, and a second time with the resolved value.
// This double pass rendering cycle comes with a drawback. It's slower.
// You can set the noSsr option to true if you are doing client-side only rendering.
// Link: https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering

const noSsr = false
const useResponsive = () => {
  const xs = useMediaQuery('(max-width:576px)', { noSsr })
  const sm = useMediaQuery('(max-width:767px)', { noSsr })
  const md = useMediaQuery('(min-width:768px) and (max-width:991px)', { noSsr })
  const lg = useMediaQuery('(min-width:992px) and (max-width:1199px)', { noSsr })
  const xl = useMediaQuery('(min-width:1200px) and (max-width:1399px)', { noSsr })
  const xxl = useMediaQuery('(min-width:1400px)', { noSsr })

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl
  }
}

export default useResponsive
