import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'

import { cn, parsedContent } from '@/lib/utils'
import { assets, capitalize } from '@/helpers/common'
import { DESTROY_INFO_MESSAGE } from '@/store/constants/actionTypes'

import 'react-toastify/dist/ReactToastify.css'

const ToasterInfoMessages = props => {
  useEffect(() => {
    if (props.globalData.showInfoMessage) {
      let options = Object.assign({}, props.globalData.infoMessageOptions)
      showInfoMessage(options)
    }
  }, [props.globalData.showInfoMessage, props.globalData.infoMessageOptions])

  const showInfoMessage = options => {
    const Msg = ({ toastProps, title, message }) => {
      let titleElements = ''
      let messageElements = ''

      const hasError = toastProps.type === 'error'
      // console.log({ toastProps, hasError })

      if (title) {
        titleElements = title
      } else {
        titleElements = capitalize(toastProps.type)
      }

      if (message.constructor.name === 'Array' && message?.length > 0) {
        messageElements = (
          <ul>
            {message.map((item, index) => (
              <li key={index} className={cn('', { 'text-red-strong': hasError }, parsedContent(item).classes)}>
                {parsedContent(item).html}
              </li>
            ))}
          </ul>
        )
      } else {
        // messageElements = <p>{message}</p>
        messageElements = <p className={cn('', { 'text-red-strong': hasError })}>{parsedContent(message).html}</p>
      }

      return (
        <>
          <h3 className={cn('title', parsedContent(titleElements).classes)}>{parsedContent(titleElements).html}</h3>
          {messageElements}
        </>
      )
    }
    const icon = ({ type }) => {
      let icon = {
        success: <img alt={'success'} src={assets('images/icon-success.png')} />,
        error: <img alt={'error'} src={assets('images/icon-danger.png')} />,
        info: <img alt={'info'} src={assets('images/icon-info.png')} />,
        warning: <img alt={'warning'} src={assets('images/icon-warning.png')} />
      }

      return icon[type]
    }

    toast(<Msg title={options.title} message={options.text} />, {
      ...options,
      icon: icon,
      // autoClose: false,
      autoClose: 2000,
      onClose: () => {
        props.onDestroyInfoMessage()
        // if(options.path){
        //     router.push(options.path);
        // }
      }
    })
  }

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} />
    </>
  )
}

const mapStateToProps = state => {
  return {
    globalData: state.globalReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onDestroyInfoMessage: () => dispatch({ type: DESTROY_INFO_MESSAGE })
})

export default connect(mapStateToProps, mapDispatchToProps)(ToasterInfoMessages)
