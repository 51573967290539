export function PlusBoldIcon(props: any) {
  return (
    <svg width={22} height={22} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.91 13.069l7.052-.004c.557 0 1.16-.326 1.532-.883.279-.556.325-1.252 0-1.763-.324-.51-.88-.88-1.53-.88l-7.051.004.003-7.052c0-.65-.37-1.206-.88-1.53-.557-.279-1.253-.325-1.763 0s-.882.882-.882 1.532l-.004 7.052-7.052.003c-.464 0-.928.187-1.253.511a1.794 1.794 0 00-.001 2.506c.324.324.788.51 1.252.51l7.052-.005-.004 7.052c0 .464.185.928.51 1.253a1.79 1.79 0 002.505-.002c.325-.325.51-.789.51-1.253l.005-7.051z'
        fill='#060F5C'
      />
    </svg>
  )
}
