import * as actionTypes from '../constants/actionTypes'

const priceStates = (() => {
  if (typeof window === 'undefined') {
    return
  }

  let orgData = JSON.parse(window.sessionStorage.getItem('orgData')) || false
  let checkPriceData = JSON.parse(window.sessionStorage.getItem('checkPriceData')) || false
  // let priceList = JSON.parse(sessionStorage.getItem('priceList')) || false;

  return {
    // countries: orgData.countries,
    states: orgData.states || false,
    warehouses: orgData.warehouses || false,
    orgData,
    checkPriceData
  }
})()

const initialState = {
  countries: false,
  statesData: [],
  paymentStates: [],
  warehouses: false,
  loadingPriceList: false,
  priceListSuccess: false,
  orgData: false,
  checkPriceData: false,
  selectedData: false,
  priceList: false,
  priceListError: false,
  editOrder: false,
  orderData: false,
  bookNowLoading: false,
  bookNowSuccess: false,
  bookNowError: false,
  senderOrigin: false,
  updateSenderOrg: false,
  loadingInsurance: false,
  paymentRequest: false,
  acceptConfirmRequest: false,
  senderOriginMsg: '',
  fedexStoreList: [],
  isFedexStoreLoading: false,
  submitResponse: false,
  insuranceInfo: {},
  handleRequest: [],
  handleSubmit: [],
  promoCodeRequest: false,
  promo_data: [],
  label_data: {},
  labelInfo: {},
  updateLabelLoading: false,
  receiver_state: [],
  receiver_request: false,
  get_tracking: [],
  ...priceStates
}

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HEADER_TYPE:
      return {
        ...state,
        handleRequest: []
      }
    case actionTypes.DESTROY_INFO_MESSAGE:
      return {
        ...state,
        handleRequest: []
      }
    case actionTypes.FETCH_COUNTRIES_START:
      return {
        ...state,
        countries: false
      }
    case actionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data
      }
    case actionTypes.FETCH_STATES_SUCCESS:
      return {
        ...state,
        statesData: action.data
      }
    case actionTypes.PAYMENT_STATES_START:
      return {
        ...state,
        paymentStates: []
      }
    case actionTypes.PAYMENT_STATES_SUCCESS:
      return {
        ...state,
        paymentStates: action.data
      }
    case actionTypes.FETCH_WAREHOUSE_SUCCESS:
      return {
        ...state,
        warehouses: action.data
      }

    //  price list
    case actionTypes.RESET_CHECK_PRICE_DATA:
      sessionStorage.removeItem('orgData')
      sessionStorage.removeItem('parent_items')
      sessionStorage.removeItem('checkPriceData')

      return {
        ...state,
        orgData: false,
        checkPriceData: false,
        priceList: false,
        priceListError: false,
        loadingPriceList: false
      }

    //   Set Price Related Data
    case actionTypes.SET_CHECK_PRICE_DATA:
      sessionStorage.setItem('orgData', JSON.stringify(action.data.orgData))
      sessionStorage.setItem('checkPriceData', JSON.stringify(action.data.filteredData))

      return {
        ...state,
        orgData: action.data.orgData,
        checkPriceData: action.data.filteredData,
        priceList: false
      }

    case actionTypes.GET_PRICE_LIST_RESET:
      return {
        ...state,
        priceList: false,
        priceListError: false,
        loadingPriceList: false,
        priceListSuccess: false
      }
    case actionTypes.GET_PRICE_LIST_START:
      return {
        ...state,
        priceList: false,
        priceListError: false,
        loadingPriceList: true
      }
    case actionTypes.GET_PRICE_LIST_SUCCESS:
      return {
        ...state,
        priceList: action.data,
        priceListError: false,
        loadingPriceList: false,
        priceListSuccess: true
      }
    case actionTypes.GET_PRICE_LIST_FAILED:
      return {
        ...state,
        priceList: false,
        loadingPriceList: false,
        priceListSuccess: false,
        priceListError: action.data
      }

    // set selected book form data
    case actionTypes.SET_SELECTED_DATA:
      return {
        ...state,
        selectedData: action.data
      }

    // book now order
    case actionTypes.BOOK_NOW_RESET:
      return {
        ...state,
        bookNowLoading: false,
        bookNowSuccess: false,
        bookNowError: false,
        priceList: false,
        priceListError: false,
        loadingPriceList: false
      }
    case actionTypes.BOOK_NOW_START:
      return {
        ...state,
        bookNowLoading: true,
        bookNowSuccess: false,
        bookNowError: false
      }
    case actionTypes.BOOK_NOW_SUCCESS:
      return {
        ...state,
        bookNowLoading: false,
        bookNowSuccess: action.data,
        bookNowError: false
      }
    case actionTypes.BOOK_NOW_FAILED:
      return {
        ...state,
        bookNowLoading: false,
        bookNowSuccess: false,
        bookNowError: action.data
      }

    // Get Edit order
    case actionTypes.GET_EDIT_ORDER_START:
      return {
        ...state,
        editOrder: false
      }
    case actionTypes.GET_EDIT_ORDER_SUCCESS:
      return {
        ...state,
        editOrder: action.data
      }
    case actionTypes.GET_EDIT_ORDER_FAILED:
      return {
        ...state,
        editOrder: false
      }
    case actionTypes.GET_EDIT_ORDER_END:
      return {
        ...state
        // editOrder: false,
      }

    // get order info
    case actionTypes.GET_ORDER_INFO_SUCCESS:
      return {
        ...state,
        orderData: action.data
      }
    case actionTypes.GET_SENDER_ORIGIN_START:
      return {
        ...state,
        senderOrigin: false
      }
    case actionTypes.GET_SENDER_ORIGIN_SUCCESS:
      return {
        ...state,
        senderOrigin: action.data
      }

    // set order origin
    case actionTypes.SET_SENDER_ORIGIN_ADDR_START:
      return {
        ...state,
        updateSenderOrg: true,
        senderOriginMsg: ''
      }
    case actionTypes.SET_SENDER_ORIGIN_ADDR_SUCC:
      return {
        ...state,
        updateSenderOrg: false,
        handleRequest: action.data
      }
    case actionTypes.SET_SENDER_ORIGIN_ADDR_FAIL:
      return {
        ...state,
        updateSenderOrg: false,
        handleRequest: action.data
      }

    // submit insurance
    case actionTypes.SUBMIT_INSURANCE_START:
      return {
        ...state,
        loadingInsurance: true
      }
    case actionTypes.SUBMIT_INSURANCE_SUCCESS:
      return {
        ...state,
        handleRequest: action.data,
        loadingInsurance: false
      }
    case actionTypes.SUBMIT_INSURANCE_FAILED:
      return {
        ...state,
        handleRequest: action.data,
        loadingInsurance: false
      }

    //  submit payment
    case actionTypes.SEND_PAYMENT_API_REQ_START:
      return {
        ...state,
        paymentRequest: true
      }
    case actionTypes.SEND_PAYMENT_API_REQ_SUCC:
      return {
        ...state,
        handleRequest: action.data
      }
    case actionTypes.SEND_PAYMENT_API_REQ_FAIL:
      return {
        ...state,
        handleRequest: action.data
      }
    case actionTypes.SEND_PAYMENT_API_REQ_END:
      return {
        ...state,
        paymentRequest: false
      }

    // submit order
    case actionTypes.SUBMIT_ORDER_START:
      return {
        ...state,
        acceptConfirmRequest: true
      }
    case actionTypes.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        handleSubmit: action.data,
        handleRequest: action.data
      }
    case actionTypes.SUBMIT_ORDER_FAILED:
      return {
        ...state,
        handleSubmit: action.data,
        handleRequest: action.data
      }
    case actionTypes.SUBMIT_ORDER_END:
      return {
        ...state,
        acceptConfirmRequest: false
      }
    case actionTypes.PROMO_CODE_START:
      return {
        ...state,
        promoCodeRequest: true
      }
    case actionTypes.PROMO_CODE_SUCC:
      return {
        ...state,
        promoCodeRequest: false,
        promo_data: action.data
      }
    case actionTypes.PROMO_CODE_FAIL:
      return {
        ...state,
        promoCodeRequest: false,
        promo_data: action.data
      }

    case actionTypes.FEDEX_STORE_LIST_START:
      return {
        ...state,
        handleRequest: [],
        isFedexStoreLoading: true
      }

    case actionTypes.FEDEX_STORE_LIST_SUCCESS:
      return {
        ...state,
        fedexStoreList: action.data
      }

    case actionTypes.FEDEX_STORE_LIST_FAILED:
      return {
        ...state,
        fedexStoreList: []
      }
    case actionTypes.FEDEX_STORE_LIST_END:
      return {
        ...state,
        isFedexStoreLoading: false
      }

    case actionTypes.GET_INSURANCE_INFO_START:
      return {
        ...state,
        insuranceInfo: {}
      }
    case actionTypes.GET_INSURANCE_INFO_SUCCESS:
      return {
        ...state,
        insuranceInfo: action.data
      }
    case actionTypes.RESET_INSURANCE_START:
      return {
        ...state,
        resetinsurance: [],
        handleRequest: [],
        loadingInsurance: true
      }
    case actionTypes.RESET_INSURANCE_SUCCESS:
      return {
        ...state,
        resetinsurance: action.data,
        handleRequest: action.data,
        loadingInsurance: false
      }

    case actionTypes.GET_LABEL_INFO_START:
      return {
        ...state,
        label_data: {}
      }
    case actionTypes.GET_LABEL_INFO_SUCCESS:
      return {
        ...state,
        label_data: action.data
      }
    case actionTypes.GET_LABEL_INFO_FAILED:
      return {
        ...state,
        label_data: action.data
      }
    case actionTypes.SET_LABEL_INFO_START:
      return {
        ...state,
        updateLabelLoading: true
      }
    case actionTypes.SET_LABEL_INFO_SUCCESS:
      return {
        ...state,
        labelInfo: action.data,
        updateLabelLoading: false
      }
    case actionTypes.SET_LABEL_INFO_FAILED:
      return {
        ...state,
        labelInfo: action.data,
        updateLabelLoading: false
      }
    case actionTypes.SET_RECEIVER_START:
      return {
        ...state,
        receiver_request: true
      }
    case actionTypes.SET_RECEIVER_SUCCESS:
      return {
        ...state,
        receiver_request: false,
        handleRequest: action.data
      }
    case actionTypes.SET_RECEIVER_FAILED:
      return {
        ...state,
        receiver_request: false,
        handleRequest: action.data
      }
    case actionTypes.RECEIVER_STATES_SUCCESS:
      return {
        ...state,
        receiver_state: action.data
      }
    case actionTypes.GET_TRACKING_INFO_START:
      return {
        ...state,
        get_tracking: []
      }
    case actionTypes.GET_TRACKING_INFO_SUCCESS:
      return {
        ...state,
        get_tracking: action.data
      }
    case actionTypes.GET_TRACKING_INFO_FAILED:
      return {
        ...state,
        get_tracking: action.data
      }

    default:
      return state
  }
}

export default orderReducer
