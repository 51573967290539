import { appUrl } from '@/configs/server'
import { isEmpty } from 'lodash'
import React from 'react'

export function assets(path) {
  return appUrl + '/' + path
}

export function onlyNumberAllow(event, max_length = 0) {
  let charCode = event.which ? event.which : event.keyCode
  let ctrlDown = event.ctrlKey || event.metaKey // Mac support
  let shiftKey = event.shiftKey || event.metaKey // Mac support

  if (ctrlDown || shiftKey) {
    event.preventDefault()

    return false
  }

  if (charCode === 8) {
    return true
  }

  if (max_length !== false) {
    if (String(event.target.value).length <= max_length) {
      if (
        (charCode >= 96 && charCode <= 105) ||
        (charCode >= 48 && charCode <= 57) ||
        charCode === 8 ||
        charCode === 9
      ) {
        return true
      }
    }
  } else {
    if ((charCode >= 96 && charCode <= 105) || (charCode >= 48 && charCode <= 57) || charCode === 8 || charCode === 9) {
      return true
    }
  }
  event.preventDefault()
}

export function onlyValidChar(event, max_length = false) {
  let charCode = event.which ? event.which : event.keyCode
  // console.log(charCode);
  // var ctrlDown = false,
  //     ctrlKey = 17,
  //     cmdKey = 91,
  //     vKey = 86,
  //     cKey = 67;
  let ctrlDown = event.ctrlKey || event.metaKey // Mac support
  let shiftKey = event.shiftKey || event.metaKey // Mac support

  if (ctrlDown || shiftKey) {
    event.preventDefault()

    return false
  }

  if (charCode === 8) {
    return true
  }

  if (max_length !== false) {
    if (String(event.target.value).length < max_length) {
      if (
        (charCode >= 96 && charCode <= 105) ||
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 65 && charCode <= 90) ||
        charCode === 9 ||
        charCode === 110
      ) {
        return true
      }
    }
  } else {
    if (
      (charCode >= 96 && charCode <= 105) ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      charCode === 9 ||
      charCode === 110
    ) {
      return true
    }
  }
  event.preventDefault()
}

export function textFilter(string) {
  let title = string.replace(/[_]/gi, ' ')
  title = capitalize(title)
  title = title.replace(/(afternoon)/gi, 'PM')

  return title
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeFirst(s) {
  return s[0].toUpperCase() + s.slice(1)
}

export function wordUpperCase(str) {
  var splitStr = str.replaceAll(/_/gi, ' ').toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }

  // Directly return the joined string
  return splitStr.join(' ')
}

export function zeroPad(num, places = 2) {
  if (num > 0) {
    return String(num).padStart(places, '0')
  }

  return 0
}

//encryption value
export function encrypt(str) {
  return btoa(str)
}

// decryption value
export function decrypt(encrypted) {
  return atob(encrypted)
}

export function filterCheckPricePost(formData) {
  const country_from = formData?.country_from || formData?.country

  let postData = {
    country_from: country_from?.country_id,
    country_to: formData?.country_to?.id || 226,
    // city_to: formData.warehouse.zip_code + ' - ' + formData.warehouse.city,
    city_to: !!formData.selectedStorageAddress ? formData.selectedStorageAddress?.city_to : formData?.city_to,
    storage_address_id: !!formData.selectedStorageAddress ? formData.selectedStorageAddress?.id : undefined,
    shipping_date: formData.pickUpDate, //shipping_date_only,
    items: formData.items,
    storage_service: formData.serviceType,
    storage: formData.warehouse.id
  }

  if (Object.keys(formData.state).length > 0) {
    postData.city_from = formData.state.zip + ' - ' + formData.state.primary_city
  } else {
    postData.city_from = formData.state
  }

  if (formData.serviceType === 0) {
    const city_to =
      formData?.state_to && Object.keys(formData?.state_to).length > 0
        ? formData?.state_to?.zip + ' - ' + formData?.state_to?.primary_city
        : ''
    postData.country_to = formData.country_to.country_id
    postData.city_to = formData.country_to.country_id === 226 ? city_to : undefined
    postData.storage = 0
  }

  if (formData.serviceType === 0 && country_from?.country_id === 226 && formData.country_to.country_id === 226) {
    postData.domestic = 1
  } else if (formData.serviceType === 1 && country_from?.country_id === 226) {
    postData.domestic = 1
  } else {
    postData.domestic = 0
  }

  return postData
}

export function allowedCountries() {
  return [226] // list of the countries allowed for shipment
}

export const getPrice = item => {
  let price = item?.price?.toFixed(2)

  if (String(price)?.length > 8) {
    return <strong className='small'>${price}</strong>
  }

  return <strong>${price}</strong>
}

export const displayPrice = item => {
  let price = item?.display_price

  if (price?.length > 10) {
    return <strong className='text-sm'>${price}</strong>
  }

  return <strong>${price}</strong>
}

export const getTime = item => {
  if (!isEmpty(item?.time)) {
    let time = item?.time?.replace('to', '<br/>to<br/>')

    return <span dangerouslySetInnerHTML={{ __html: time }}></span>
  }

  return ''
}
