import Configs from '@/configs'
import * as types from '@/store/constants/actionTypes'
import axios from 'axios'
import { put } from 'redux-saga/effects'
import { getEncryptedCookie } from '@/v1/lib/cookie'
import CookieKeys from '@/configs/caching/keys/cookie'
import { handleApiError, redirectToLogin } from '@/store/sagas/handle-api-error'

// GET dashboard statistic
export function* getUserOrderStatistic() {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token) {
      yield put({ type: types.GET_USER_ORDER_STATISTIC_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let result = yield instance
        .get('/user-dashboard/header-statistics')
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200 && result.data.status) {
        yield put({ type: types.GET_USER_ORDER_STATISTIC_SUCCESS, data: { ...result.data.data[0] } })
      } else {
        // yield put({ type: types.GET_USER_ORDER_STATISTIC_FAILED});
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_USER_ORDER_STATISTIC_FAILED })
    }
    yield put({ type: types.GET_USER_ORDER_STATISTIC_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my shipment statistic
export function* getMyShipmentStatistic() {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token) {
      yield put({ type: types.GET_MY_SHIPMENT_ORDER_STATISTIC_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let result = yield instance
        .get('/user-dashboard/my-shipment-statistics')
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_SHIPMENT_ORDER_STATISTIC_SUCCESS, data: { ...result.data.data[0] } })
      } else {
        yield put({ type: types.GET_MY_SHIPMENT_ORDER_STATISTIC_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_SHIPMENT_ORDER_STATISTIC_FAILED })
    }
    yield put({ type: types.GET_MY_SHIPMENT_ORDER_STATISTIC_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my storage statistic
export function* getMyStorageStatistic() {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token) {
      yield put({ type: types.GET_MY_STORAGE_ORDER_STATISTIC_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let result = yield instance
        .get('/user-dashboard/dashboard-orders-count')
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_STORAGE_ORDER_STATISTIC_SUCCESS, data: { ...result.data.data[0] } })
      } else {
        yield put({ type: types.GET_MY_STORAGE_ORDER_STATISTIC_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_STORAGE_ORDER_STATISTIC_FAILED })
    }
    yield put({ type: types.GET_MY_STORAGE_ORDER_STATISTIC_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET incomplete order
export function* getIncompleteOrders(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_INCOMPLETE_ORDER_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let per_page = action.data.per_page ? action.data.per_page : 6
      let url = 'user-dashboard/get-incomplete-orders/' + per_page + '?page=' + action.data.page // action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_INCOMPLETE_ORDER_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_INCOMPLETE_ORDER_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_INCOMPLETE_ORDER_FAILED })
    }
    yield put({ type: types.GET_INCOMPLETE_ORDER_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET order by status
export function* getOrderByStatus(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_SS_ORDER_BY_STATUS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let per_page = action.data.per_page ? action.data.per_page : 20
      let page = action.data.page ? action.data.page : 1
      let status = action.data.status
      let url = `/user-dashboard/my-shipment-list?shipping_status=${status}&per_page=${per_page}&page=${page}`

      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_SS_ORDER_BY_STATUS_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_SS_ORDER_BY_STATUS_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_SS_ORDER_BY_STATUS_FAILED })
    }
    yield put({ type: types.GET_SS_ORDER_BY_STATUS_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET Download Print Label
export function* getDownloadPrintLabel(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_DOWNLOAD_PRINT_LABEL_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        },
        responseType: 'blob'
      })

      let result = yield instance
        .get('order/download/label/' + action.data.order_id)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', action.data.order_number + '.pdf') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()

        yield put({ type: types.GET_DOWNLOAD_PRINT_LABEL_SUCCESS })
      } else {
        yield put({ type: types.GET_DOWNLOAD_PRINT_LABEL_FAILED, data: 'Could not found label.' })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_DOWNLOAD_PRINT_LABEL_FAILED, data: 'Could not found label.' })
    }
    yield put({ type: types.GET_DOWNLOAD_PRINT_LABEL_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my shipment history
export function* getShipmentHistoryOrders(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_SHIPMENT_HISTORY_ORDERS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      // shipping_status=incomplete,close/cancelled,delivered

      let per_page = action.data.per_page ? action.data.per_page : 20
      let page = action.data.page ? action.data.page : 1
      let status = action.data.status
      let url = `/user-dashboard/my-shipment-history?shipping_status=${status}&per_page=${per_page}&page=${page}`

      // let per_page = action.data.per_page ? action.data.per_page : 20
      /* let url =
        'user-dashboard/my-shipment-history' +
        '?shipping_status=incomplete,close' +
        '&per_page=' +
        per_page +
        '&page=' +
        action.data.page // action.data*/

      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_SHIPMENT_HISTORY_ORDERS_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_SHIPMENT_HISTORY_ORDERS_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_SHIPMENT_HISTORY_ORDERS_FAILED })
    }
    yield put({ type: types.GET_SHIPMENT_HISTORY_ORDERS_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET order details
export function* getOrderDetails(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_ORDER_DETAILS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('/user-dashboard/get-order-details/' + action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_ORDER_DETAILS_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.GET_ORDER_DETAILS_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_ORDER_DETAILS_FAILED })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// PUT cancel order
export function* putCancelOrder(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.PUT_CANCEL_ORDER_START, data: action.data })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .put('/user-dashboard/cancel-an-order/' + action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200 && result.data.status) {
        yield put({ type: types.PUT_CANCEL_ORDER_SUCCESS, data: result.data.message })
      } else {
        yield put({
          type: types.PUT_CANCEL_ORDER_FAILED,
          data: result.data.message ?? 'Opps! system is unable to cancel this order'
        })
      }
    } else {
      redirectToLogin()
      yield put({
        type: types.PUT_CANCEL_ORDER_FAILED,
        data: result.data.message ?? 'Opps! system is unable to cancel this order'
      })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
