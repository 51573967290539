import { v4 as uuidV4 } from 'uuid'
import { createSlice } from '@reduxjs/toolkit'

const eachItem = {
  id: uuidV4(),
  item_name: null,
  item_count: '',
  item_price: '',
  total: 0
}

const initialState = {
  apiData: {
    steps: null,
    item_list: null,
    order_info: null,
    travel_itinerary: null,
    shipping_purpose: null,
    supporting_documents: null
  },
  items: [eachItem],
  grandTotal: 0,
  totalItems: 0,
  signature: null,
  item_list_documents: [],
  isProcessed: false,
  submitted: false,
  openSignaturePad: false
}

const customDocumentSlice = createSlice({
  name: 'customDocument',
  initialState,
  reducers: {
    storeCustomDocumentInfo: (state, action) => {
      const steps = action.payload?.steps
      const itemList = action.payload?.item_list
      const orderInfo = action.payload?.order_info
      const travelItinerary = action.payload?.travel_itinerary
      const shippingPurpose = action.payload?.shipping_purpose
      const supportingDocuments = action.payload?.supporting_documents

      state.apiData = {
        ...state.apiData,
        steps,
        item_list: itemList,
        order_info: orderInfo,
        travel_itinerary: travelItinerary,
        shipping_purpose: shippingPurpose,
        supporting_documents: supportingDocuments
      }

      // console.log({ supportingDocuments })

      if (orderInfo?.status === 'Processed') {
        state.isProcessed = true
      }

      const passportInfo = supportingDocuments?.passport_info

      if (!!passportInfo) {
        state.supportingDocuments = {
          ...state.supportingDocuments,
          country_id: passportInfo?.passport_country_id ?? '',
          pass_number: passportInfo?.passport_number ?? ''
        }
      }

      if (!!itemList?.order_item_list?.length) {
        state.items = itemList?.order_item_list
      }

      const signature = itemList?.signature

      if (!!signature) {
        state.signature = signature
      }

      // Calculate and update grandTotal and totalItems
      const { grandTotal, totalItems } = calculateTotals(state.items)
      state.grandTotal = grandTotal
      state.totalItems = totalItems
    },
    updateCustomDocument: (state, action) => {
      const { name, value } = action.payload

      state[name] = value
    },
    addNewItem: state => {
      state.items = [
        ...state.items,
        {
          id: uuidV4(),
          item_name: null,
          item_count: '',
          item_price: '',
          total: 0
        }
      ]
    },
    updateItem: (state, action) => {
      const { id, name, value } = action.payload

      // Find the index of the item in the state.items array
      const index = state.items.findIndex(item => item.id === id)

      if (index !== -1) {
        // If the item is found, update the specified property
        state.items[index] = {
          ...state.items[index],
          [name]: value
        }

        // Calculate total after updating item properties
        const itemCount = parseFloat(state.items[index].item_count)
        const itemPrice = parseFloat(state.items[index].item_price)

        let total = itemCount * itemPrice
        total = isNaN(total) ? 0 : total // Set total to 0 if it's NaN

        state.items[index].total = total.toFixed(2)
      } else {
        // If the item is not found, you might want to handle this case accordingly
        console.error(`Item with ID ${id} not found.`)
      }

      // Calculate and update grandTotal and totalItems
      const { grandTotal, totalItems } = calculateTotals(state.items)
      state.grandTotal = grandTotal
      state.totalItems = totalItems
    },
    removeItem: (state, action) => {
      const selectedId = action.payload.id
      state.items = state.items.filter(item => item.id !== selectedId)

      // Calculate and update grandTotal and totalItems
      const { grandTotal, totalItems } = calculateTotals(state.items)
      state.grandTotal = grandTotal
      state.totalItems = totalItems
    },
    storeSignaturePad: (state, action) => {
      state.signature = action.payload
    }
  }
})

export const { storeCustomDocumentInfo, updateCustomDocument, addNewItem, updateItem, removeItem, storeSignaturePad } =
  customDocumentSlice.actions
export default customDocumentSlice.reducer

function calculateTotals(items) {
  const grandTotal = items.reduce((acc, item) => acc + parseFloat(item.total), 0)
  const totalItems = items.reduce((acc, item) => acc + Number(item.item_count), 0)

  return { grandTotal: grandTotal ? grandTotal.toFixed(2) : 0, totalItems: totalItems ?? 0 }
}
