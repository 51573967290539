import * as actionTypes from '../constants/actionTypes'
// import config from '../../config';

const initialState = {
  isOpen: [], //for active default menu
  isTrigger: [], //for active default menu, set blank for horizontal
  isFullScreen: false, // static can't change ,
  showFullScreenLoader: false,
  showInfoMessage: false,
  infoMessageOptions: {
    type: 'info',
    title: '',
    text: 'Successfull',
    position: 'top-center',
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined
  },
  headerType: '',
  pageBanner: false,

  captchaLoading: false,
  captchaData: false,
  captchaError: false,

  contactLoading: false,
  contactSuccess: false,
  contactError: false,

  openTrackingModal: false
}

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_FULL_SCREEN_LOADER:
      return {
        ...state,
        showFullScreenLoader: true
      }
    case actionTypes.HIDE_FULL_SCREEN_LOADER:
      return {
        ...state,
        showFullScreenLoader: false
      }
    case actionTypes.SHOW_INFO_MESSAGE:
      let options = {}
      if (action.options) {
        options = action.options
      }

      return {
        ...state,
        showInfoMessage: true,
        infoMessageOptions: {
          ...state.infoMessageOptions,
          ...options
        }
      }
    case actionTypes.DESTROY_INFO_MESSAGE:
      return {
        ...state,
        showInfoMessage: false,
        infoMessageOptions: {
          text: 'Successfull',
          position: 'top-center',
          autoClose: 10000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: false
        }
      }
    case actionTypes.SET_PAGE_BANNER:
      return {
        ...state,
        pageBanner: action.data
      }
    case actionTypes.SET_HEADER_TYPE:
      return {
        ...state,
        headerType: action.data
      }

    case actionTypes.GET_CAPTCHA_START:
      return {
        ...state,
        captchaLoading: true,
        captchaData: false,
        captchaError: false
      }
    case actionTypes.GET_CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaLoading: false,
        captchaData: action.data,
        captchaError: false
      }

    case actionTypes.SUBMIT_CONTACT_FORM_START:
      return {
        ...state,
        contactLoading: true,
        contactSuccess: false,
        contactError: false
      }
    case actionTypes.SUBMIT_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        contactLoading: false,
        contactSuccess: action.data,
        contactError: false
      }
    case actionTypes.SUBMIT_CONTACT_FORM_FAILED:
      return {
        ...state,
        contactLoading: false,
        contactSuccess: false,
        contactError: action.data
      }
    case actionTypes.SUBMIT_CONTACT_FORM_END:
      return {
        ...state,
        contactLoading: false,
        contactSuccess: false,
        contactError: false
      }

    //   Tracking Number
    case actionTypes.HANDLE_TRACKING_MODAL:
      return {
        ...state,
        openTrackingModal: action.data
      }

    default:
      return state
  }
}

export default globalReducer
