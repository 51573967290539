import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import { FooterLinkItem } from '@/layouts/v1/footer'
import { CrossCloseIcon, PlusBoldIcon } from '@/layouts/v1/footer/icons'

interface FooterLinksProps {
  title?: string
  items: { id: number; url: string; label: string; className?: string }[]
  keyName?: string
  expanded: string | boolean
  setExpanded: (value: string | boolean) => void
}

export function FooterLinksAccordion(props: FooterLinksProps) {
  const { title, items, keyName = 'panel1', expanded, setExpanded } = props
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <Accordion
        expanded={expanded === keyName}
        className={'m-0 min-h-10 bg-transparent !shadow-none'}
        onChange={handleChange(keyName)}
      >
        <AccordionSummary
          aria-controls={`${keyName}d-content`}
          id={`${keyName}d-header`}
          className={'min-h-10 !bg-transparent px-0 [&_.MuiAccordionSummary-content]:!m-0'}
          expandIcon={
            <div className={'mx-auto min-w-8'}>
              {expanded === keyName ? <CrossCloseIcon className={'mx-auto'} /> : <PlusBoldIcon className={'mx-auto'} />}
            </div>
          }
        >
          <div className='flex min-h-10 items-center'>
            <p className={'text-lg font-semibold text-gray-dark-slate'}>{title}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className={'px-0 pb-0 pt-0'}>
          <FooterLinkItem items={items} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
