// Screen related Constants
export const SHOW_FULL_SCREEN_LOADER = 'SHOW_FULL_SCREEN_LOADER'
export const HIDE_FULL_SCREEN_LOADER = 'HIDE_FULL_SCREEN_LOADER'

// Info message action
export const SHOW_INFO_MESSAGE = 'SHOW_INFO_MESSAGE'
export const DESTROY_INFO_MESSAGE = 'DESTROY_INFO_MESSAGE'
export const SET_PAGE_BANNER = 'SET_PAGE_BANNER'
export const DESTROY_PAGE_BANNER = 'DESTROY_PAGE_BANNER'

export const SET_HEADER_TYPE = 'SET_HEADER_TYPE'

export const HEADER_ERROR = 'HEADER_ERROR'

// Country Actions
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES'
export const FETCH_COUNTRIES_START = 'FETCH_COUNTRIES_START'
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED'

// Country Actions
export const FETCH_STATES = 'FETCH_STATES'
export const FETCH_STATES_START = 'FETCH_STATES_START'
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS'
export const FETCH_STATES_FAILED = 'FETCH_STATES_FAILED'

export const PAYMENT_STATES = 'PAYMENT_STATES'
export const PAYMENT_STATES_START = 'PAYMENT_STATES_START'
export const PAYMENT_STATES_SUCCESS = 'PAYMENT_STATES_SUCCESS'
export const PAYMENT_STATES_FAILED = 'PAYMENT_STATES_FAILED'

// Warehouse Actions
export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE'
export const FETCH_WAREHOUSE_START = 'FETCH_WAREHOUSE_START'
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS'
export const FETCH_WAREHOUSE_FAILED = 'FETCH_WAREHOUSE_FAILED'

// Register Actions
export const USER_REGISTER = 'USER_REGISTER'
export const USER_REGISTER_START = 'USER_REGISTER_START'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED'
export const USER_REGISTER_END = 'USER_REGISTER_END'

// Login Actions
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_START = 'USER_LOGIN_START'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED'
export const USER_LOGIN_END = 'USER_LOGIN_END'

// Logout Actions
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_START = 'USER_LOGOUT_START'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED'
export const USER_LOGOUT_END = 'USER_LOGOUT_END'

// Auth Checking and set user action
export const CHECK_AUTH_USER = 'CHECK_AUTH_USER'
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const AUTH_FAILED = 'AUTH_FAILED'

// Reset password Actions
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT'
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const RESET_PASSWORD_END = 'RESET_PASSWORD_END'

// Reset password Actions
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT'
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'
export const CHANGE_PASSWORD_END = 'CHANGE_PASSWORD_END'

// Check Price
export const RESET_CHECK_PRICE_DATA = 'RESET_CHECK_PRICE_DATA'
export const SET_CHECK_PRICE_DATA = 'SET_CHECK_PRICE_DATA'
export const GET_PRICE_LIST = 'GET_PRICE_LIST'
export const GET_PRICE_LIST_START = 'GET_PRICE_LIST_START'
export const GET_PRICE_LIST_RESET = 'GET_PRICE_LIST_RESET'
export const GET_PRICE_LIST_SUCCESS = 'GET_PRICE_LIST_SUCCESS'
export const GET_PRICE_LIST_FAILED = 'GET_PRICE_LIST_FAILED'
export const GET_PRICE_LIST_END = 'GET_PRICE_LIST_END'

// order steps
export const GET_ORDER_INFO = 'GET_ORDER_INFO'
export const GET_ORDER_INFO_START = 'GET_ORDER_INFO_START'
export const GET_ORDER_INFO_SUCCESS = 'GET_ORDER_INFO_SUCCESS'
export const GET_SENDER_ORIGIN_ADDR = 'GET_SENDER_ORIGIN_ADDR'
export const GET_SENDER_ORIGIN_START = 'GET_SENDER_ORIGIN_START'
export const GET_SENDER_ORIGIN_SUCCESS = 'GET_SENDER_ORIGIN_SUCCESS'
export const GET_SENDER_ORIGIN_END = 'GET_SENDER_ORIGIN_END'

export const SET_SENDER_ORIGIN = 'SET_SENDER_ORIGIN'
export const SET_SENDER_ORIGIN_ADDR_START = 'SET_SENDER_ORIGIN_ADDR_START'
export const SET_SENDER_ORIGIN_ADDR_END = 'SET_SENDER_ORIGIN_ADDR_END'
export const SET_SENDER_ORIGIN_ADDR_SUCC = 'SET_SENDER_ORIGIN_ADDR_SUCC'
export const SET_SENDER_ORIGIN_ADDR_FAIL = 'SET_SENDER_ORIGIN_ADDR_FAIL'

//submit payment
export const SEND_PAYMENT_API_REQ = 'SEND_PAYMENT_API_REQ'
export const SEND_PAYMENT_API_REQ_START = 'SEND_PAYMENT_API_REQ_START'
export const SEND_PAYMENT_API_REQ_SUCC = 'SEND_PAYMENT_API_REQ_SUCC'
export const SEND_PAYMENT_API_REQ_FAIL = 'SEND_PAYMENT_API_REQ_FAIL'
export const SEND_PAYMENT_API_REQ_END = 'SEND_PAYMENT_API_REQ_END'

// promo code applied
export const PROMO_CODE = 'PROMO_CODE'
export const PROMO_CODE_START = 'PROMO_CODE_START'
export const PROMO_CODE_SUCC = 'PROMO_CODE_SUCC'
export const PROMO_CODE_FAIL = 'PROMO_CODE_FAIL'
export const PROMO_CODE_END = 'PROMO_CODE_END'

// submit order
export const SUBMIT_ORDER = 'SUBMIT_ORDER'
export const SUBMIT_ORDER_START = 'SUBMIT_ORDER_START'
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS'
export const SUBMIT_ORDER_FAILED = 'SUBMIT_ORDER_FAILED'
export const SUBMIT_ORDER_END = 'SUBMIT_ORDER_END'

// submit insurance
export const SUBMIT_INSURANCE = 'SUBMIT_INSURANCE'
export const SUBMIT_INSURANCE_START = 'SUBMIT_INSURANCE_START'
export const SUBMIT_INSURANCE_SUCCESS = 'SUBMIT_INSURANCE_SUCCESS'
export const SUBMIT_INSURANCE_FAILED = 'SUBMIT_INSURANCE_FAILED'
export const SUBMIT_INSURANCE_END = 'SUBMIT_INSURANCE_END'

// GET INSURANCE information
export const GET_INSURANCE_INFO = 'GET_INSURANCE_INFO'
export const GET_INSURANCE_INFO_START = 'GET_INSURANCE_INFO_START'
export const GET_INSURANCE_INFO_SUCCESS = 'GET_INSURANCE_INFO_SUCCESS'
export const GET_INSURANCE_INFO_FAILED = 'GET_INSURANCE_INFO_FAILED'
export const GET_INSURANCE_INFO_END = 'GET_INSURANCE_INFO_END'

// GET label information
export const GET_LABEL_INFO = 'GET_LABEL_INFO'
export const GET_LABEL_INFO_START = 'GET_LABEL_INFO_START'
export const GET_LABEL_INFO_SUCCESS = 'GET_LABEL_INFO_SUCCESS'
export const GET_LABEL_INFO_FAILED = 'GET_LABEL_INFO_FAILED'
export const GET_LABEL_INFO_END = 'GET_LABEL_INFO_END'

// Update label information
export const SET_LABEL_INFO = 'SET_LABEL_INFO'
export const SET_LABEL_INFO_START = 'SET_LABEL_INFO_START'
export const SET_LABEL_INFO_SUCCESS = 'SET_LABEL_INFO_SUCCESS'
export const SET_LABEL_INFO_FAILED = 'SET_LABEL_INFO_FAILED'
export const SET_LABEL_INFO_END = 'SET_LABEL_INFO_END'

// RESET insurance fee
export const RESET_INSURANCE = 'RESET_INSURANCE'
export const RESET_INSURANCE_START = 'RESET_INSURANCE_START'
export const RESET_INSURANCE_SUCCESS = 'RESET_INSURANCE_SUCCESS'
export const RESET_INSURANCE_FAILED = 'RESET_INSURANCE_FAILED'
export const RESET_INSURANCE_END = 'RESET_INSURANCE_END'

// book now actions
export const SET_SELECTED_DATA = 'SET_SELECTED_DATA'
export const BOOK_NOW_RESET = 'BOOK_NOW_RESET'
export const BOOK_NOW = 'BOOK_NOW'
export const BOOK_NOW_START = 'BOOK_NOW_START'
export const BOOK_NOW_SUCCESS = 'BOOK_NOW_SUCCESS'
export const BOOK_NOW_FAILED = 'BOOK_NOW_FAILED'
export const BOOK_NOW_END = 'BOOK_NOW_END'

// dashboard order statistic actions
export const GET_USER_ORDER_STATISTIC = 'GET_USER_ORDER_STATISTIC'
export const GET_USER_ORDER_STATISTIC_START = 'GET_USER_ORDER_STATISTIC_START'
export const GET_USER_ORDER_STATISTIC_SUCCESS = 'GET_USER_ORDER_STATISTIC_SUCCESS'
export const GET_USER_ORDER_STATISTIC_FAILED = 'GET_USER_ORDER_STATISTIC_FAILED'
export const GET_USER_ORDER_STATISTIC_END = 'GET_USER_ORDER_STATISTIC_END'

// my shipment order statistic actions
export const GET_MY_SHIPMENT_ORDER_STATISTIC = 'GET_MY_SHIPMENT_ORDER_STATISTIC'
export const GET_MY_SHIPMENT_ORDER_STATISTIC_START = 'GET_MY_SHIPMENT_ORDER_STATISTIC_START'
export const GET_MY_SHIPMENT_ORDER_STATISTIC_SUCCESS = 'GET_MY_SHIPMENT_ORDER_STATISTIC_SUCCESS'
export const GET_MY_SHIPMENT_ORDER_STATISTIC_FAILED = 'GET_MY_SHIPMENT_ORDER_STATISTIC_FAILED'
export const GET_MY_SHIPMENT_ORDER_STATISTIC_END = 'GET_MY_SHIPMENT_ORDER_STATISTIC_END'

// my shipment order statistic actions
export const GET_INCOMPLETE_ORDER = 'GET_INCOMPLETE_ORDER'
export const GET_INCOMPLETE_ORDER_START = 'GET_INCOMPLETE_ORDER_START'
export const GET_INCOMPLETE_ORDER_SUCCESS = 'GET_INCOMPLETE_ORDER_SUCCESS'
export const GET_INCOMPLETE_ORDER_FAILED = 'GET_INCOMPLETE_ORDER_FAILED'
export const GET_INCOMPLETE_ORDER_END = 'GET_INCOMPLETE_ORDER_END'

// Order details actions
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS'
export const GET_ORDER_DETAILS_START = 'GET_ORDER_DETAILS_START'
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS'
export const GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED'
export const GET_ORDER_DETAILS_END = 'GET_ORDER_DETAILS_END'

// Submitted Shipment order Actions
export const GET_SS_ORDER_BY_STATUS = 'GET_SS_ORDER_BY_STATUS'
export const GET_SS_ORDER_BY_STATUS_START = 'GET_SS_ORDER_BY_STATUS_START'
export const GET_SS_ORDER_BY_STATUS_SUCCESS = 'GET_SS_ORDER_BY_STATUS_SUCCESS'
export const GET_SS_ORDER_BY_STATUS_FAILED = 'GET_SS_ORDER_BY_STATUS_FAILED'
export const GET_SS_ORDER_BY_STATUS_END = 'GET_SS_ORDER_BY_STATUS_END'

export const GET_DOWNLOAD_PRINT_LABEL = 'GET_DOWNLOAD_PRINT_LABEL'
export const GET_DOWNLOAD_PRINT_LABEL_START = 'GET_DOWNLOAD_PRINT_LABEL_START'
export const GET_DOWNLOAD_PRINT_LABEL_SUCCESS = 'GET_DOWNLOAD_PRINT_LABEL_SUCCESS'
export const GET_DOWNLOAD_PRINT_LABEL_FAILED = 'GET_DOWNLOAD_PRINT_LABEL_FAILED'
export const GET_DOWNLOAD_PRINT_LABEL_END = 'GET_DOWNLOAD_PRINT_LABEL_END'

// Shipment history order Actions
export const GET_SHIPMENT_HISTORY_ORDERS = 'GET_SHIPMENT_HISTORY_ORDERS'
export const GET_SHIPMENT_HISTORY_ORDERS_START = 'GET_SHIPMENT_HISTORY_ORDERS_START'
export const GET_SHIPMENT_HISTORY_ORDERS_SUCCESS = 'GET_SHIPMENT_HISTORY_ORDERS_SUCCESS'
export const GET_SHIPMENT_HISTORY_ORDERS_FAILED = 'GET_SHIPMENT_HISTORY_ORDERS_FAILED'
export const GET_SHIPMENT_HISTORY_ORDERS_END = 'GET_SHIPMENT_HISTORY_ORDERS_END'

// Submitted Shipment order Actions
export const PUT_CANCEL_ORDER = 'PUT_CANCEL_ORDER'
export const PUT_CANCEL_ORDER_RESET = 'PUT_CANCEL_ORDER_RESET'
export const PUT_CANCEL_ORDER_START = 'PUT_CANCEL_ORDER_START'
export const PUT_CANCEL_ORDER_SUCCESS = 'PUT_CANCEL_ORDER_SUCCESS'
export const PUT_CANCEL_ORDER_FAILED = 'PUT_CANCEL_ORDER_FAILED'
export const PUT_CANCEL_ORDER_END = 'PUT_CANCEL_ORDER_END'

//FEDEX store list
export const FEDEX_STORE_LIST = 'FEDEX_STORE_LIST'
export const FEDEX_STORE_LIST_START = 'FEDEX_STORE_LIST_START'
export const FEDEX_STORE_LIST_END = 'FEDEX_STORE_LIST_END'
export const FEDEX_STORE_LIST_SUCCESS = 'FEDEX_STORE_LIST_SUCCESS'
export const FEDEX_STORE_LIST_FAILED = 'FEDEX_STORE_LIST_FAILED'

// Get Edit Order
export const GET_EDIT_ORDER = 'GET_EDIT_ORDER'
export const GET_EDIT_ORDER_START = 'GET_EDIT_ORDER_START'
export const GET_EDIT_ORDER_SUCCESS = 'GET_EDIT_ORDER_SUCCESS'
export const GET_EDIT_ORDER_FAILED = 'GET_EDIT_ORDER_FAILED'
export const GET_EDIT_ORDER_END = 'GET_EDIT_ORDER_END'

// Get Captcha
export const GET_CAPTCHA = 'GET_CAPTCHA'
export const GET_CAPTCHA_START = 'GET_CAPTCHA_START'
export const GET_CAPTCHA_SUCCESS = 'GET_CAPTCHA_SUCCESS'
export const GET_CAPTCHA_FAILED = 'GET_CAPTCHA_FAILED'
export const GET_CAPTCHA_END = 'GET_CAPTCHA_END'

// Get Captcha
export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM'
export const SUBMIT_CONTACT_FORM_START = 'SUBMIT_CONTACT_FORM_START'
export const SUBMIT_CONTACT_FORM_SUCCESS = 'SUBMIT_CONTACT_FORM_SUCCESS'
export const SUBMIT_CONTACT_FORM_FAILED = 'SUBMIT_CONTACT_FORM_FAILED'
export const SUBMIT_CONTACT_FORM_END = 'SUBMIT_CONTACT_FORM_END'

// Set receiver address
export const SET_RECEIVER = 'SET_RECEIVER'
export const SET_RECEIVER_START = 'SET_RECEIVER_START'
export const SET_RECEIVER_SUCCESS = 'SET_RECEIVER_SUCCESS'
export const SET_RECEIVER_FAILED = 'SET_RECEIVER_FAILED'
export const SET_RECEIVER_END = 'SET_RECEIVER_END'

// Set receiver address
export const RECEIVER_STATES = 'RECEIVER_STATES'
export const RECEIVER_STATES_START = 'RECEIVER_STATES_START'
export const RECEIVER_STATES_SUCCESS = 'RECEIVER_STATES_SUCCESS'
export const RECEIVER_STATES_FAILED = 'RECEIVER_STATES_FAILED'
export const RECEIVER_STATES_END = 'RECEIVER_STATES_END'

// Get tracking details
export const GET_TRACKING_INFO = 'GET_TRACKING_INFO'
export const GET_TRACKING_INFO_START = 'GET_TRACKING_INFO_START'
export const GET_TRACKING_INFO_SUCCESS = 'GET_TRACKING_INFO_SUCCESS'
export const GET_TRACKING_INFO_FAILED = 'GET_TRACKING_INFO_FAILED'
export const GET_TRACKING_INFO_END = 'GET_TRACKING_INFO_END'

// My Storage
export const OPEN_DETAILS_MODEL = 'OPEN_DETAILS_MODEL'

// my storage order statistic actions
export const GET_MY_STORAGE_ORDER_STATISTIC = 'GET_MY_STORAGE_ORDER_STATISTIC'
export const GET_MY_STORAGE_ORDER_STATISTIC_START = 'GET_MY_STORAGE_ORDER_STATISTIC_START'
export const GET_MY_STORAGE_ORDER_STATISTIC_SUCCESS = 'GET_MY_STORAGE_ORDER_STATISTIC_SUCCESS'
export const GET_MY_STORAGE_ORDER_STATISTIC_FAILED = 'GET_MY_STORAGE_ORDER_STATISTIC_FAILED'
export const GET_MY_STORAGE_ORDER_STATISTIC_END = 'GET_MY_STORAGE_ORDER_STATISTIC_END'

// my storage order list actions
export const GET_MY_STORAGE_ORDER = 'GET_MY_STORAGE_ORDER'
export const GET_MY_STORAGE_ORDER_START = 'GET_MY_STORAGE_ORDER_START'
export const GET_MY_STORAGE_ORDER_SUCCESS = 'GET_MY_STORAGE_ORDER_SUCCESS'
export const GET_MY_STORAGE_ORDER_FAILED = 'GET_MY_STORAGE_ORDER_FAILED'
export const GET_MY_STORAGE_ORDER_END = 'GET_MY_STORAGE_ORDER_END'

// my storage order list actions
export const GET_MY_STORAGE_IN_PROGRESS_ORDER = 'GET_MY_STORAGE_IN_PROGRESS_ORDER'
export const GET_MY_STORAGE_IN_PROGRESS_ORDER_START = 'GET_MY_STORAGE_IN_PROGRESS_ORDER_START'
export const GET_MY_STORAGE_IN_PROGRESS_ORDER_SUCCESS = 'GET_MY_STORAGE_IN_PROGRESS_ORDER_SUCCESS'
export const GET_MY_STORAGE_IN_PROGRESS_ORDER_FAILED = 'GET_MY_STORAGE_IN_PROGRESS_ORDER_FAILED'
export const GET_MY_STORAGE_IN_PROGRESS_ORDER_END = 'GET_MY_STORAGE_IN_PROGRESS_ORDER_END'

// my storage order details actions
export const GET_MY_STORAGE_ORDER_DETAILS = 'GET_MY_STORAGE_ORDER_DETAILS'
export const GET_MY_STORAGE_ORDER_DETAILS_START = 'GET_MY_STORAGE_ORDER_DETAILS_START'
export const GET_MY_STORAGE_ORDER_DETAILS_SUCCESS = 'GET_MY_STORAGE_ORDER_DETAILS_SUCCESS'
export const GET_MY_STORAGE_ORDER_DETAILS_FAILED = 'GET_MY_STORAGE_ORDER_DETAILS_FAILED'
export const GET_MY_STORAGE_ORDER_DETAILS_END = 'GET_MY_STORAGE_ORDER_DETAILS_END'

// my storage history order Actions
export const GET_MY_STORAGE_HISTORY_ORDERS = 'GET_MY_STORAGE_HISTORY_ORDERS'
export const GET_MY_STORAGE_HISTORY_ORDERS_START = 'GET_MY_STORAGE_HISTORY_ORDERS_START'
export const GET_MY_STORAGE_HISTORY_ORDERS_SUCCESS = 'GET_MY_STORAGE_HISTORY_ORDERS_SUCCESS'
export const GET_MY_STORAGE_HISTORY_ORDERS_FAILED = 'GET_MY_STORAGE_HISTORY_ORDERS_FAILED'
export const GET_MY_STORAGE_HISTORY_ORDERS_END = 'GET_MY_STORAGE_HISTORY_ORDERS_END'

// my storage ship and pickup details
export const GET_SHIP_AND_PICKUP_ORDER_DETAILS = 'GET_SHIP_AND_PICKUP_ORDER_DETAILS'
export const GET_SHIP_AND_PICKUP_ORDER_DETAILS_START = 'GET_SHIP_AND_PICKUP_ORDER_DETAILS_START'
export const GET_SHIP_AND_PICKUP_ORDER_DETAILS_SUCCESS = 'GET_SHIP_AND_PICKUP_ORDER_DETAILS_SUCCESS'
export const GET_SHIP_AND_PICKUP_ORDER_DETAILS_FAILED = 'GET_SHIP_AND_PICKUP_ORDER_DETAILS_FAILED'
export const GET_SHIP_AND_PICKUP_ORDER_DETAILS_END = 'GET_SHIP_AND_PICKUP_ORDER_DETAILS_END'

// TRACKING MODAL
export const HANDLE_TRACKING_MODAL = 'HANDLE_TRACKING_MODAL'
