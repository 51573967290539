const authorizedKey = process.env.NEXT_PUBLIC_AUTHORZED_KEY || 'ak'
const adminInfo = process.env.NEXT_PUBLIC_CHECK_AUTH || 'admin_info'
const tokenType = process.env.NEXT_PUBLIC_TOKEN_TYPE_KEY || 'token_type'
const expiresIn = process.env.NEXT_PUBLIC_EXPIRES_IN_KEY || 'expires_in'
const checkAuth = process.env.NEXT_PUBLIC_CHECK_AUTH || 'is_authenticated'
const accessToken = process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY || 'access_token'
const refreshToken = process.env.NEXT_PUBLIC_REFRESH_TOKEN_KEY || 'refresh_token'

export const CookieKeys = {
  tokenType,
  expiresIn,
  checkAuth,
  adminInfo,
  accessToken,
  refreshToken,
  authorizedKey
}

export default CookieKeys
