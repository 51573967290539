import { STATUS } from '@/v1/constants/status'
import { Routes } from '@/v1/constants/routes'
import { removeCookies } from '@/v1/lib/cookie'
import CookieKeys from '@/configs/caching/keys/cookie'
import SweetAlert from 'sweetalert2'

const options = {
  position: 'center',
  icon: 'warning',
  showConfirmButton: true,
  timerProgressBar: true,
  showCloseButton: false,
  allowOutsideClick: false,
  customClass: {
    actions: 'my-actions',
    cancelButton: 'order-1 right-gap',
    confirmButton: 'order-2',
    denyButton: 'order-3',
    topZIndex: 'height-z-index'
  },
  backdrop: `
      rgba(0,0,123,0.4)
      left top
      no-repeat
    `,
  html: `
      <div class="flex justify-center flex-col mx-auto">
          <p class="font-medium text-lg pb-1">Please Login Again:</p>
          <p class="text-red-strong">Your Current Login Session Has Been Expired!</p>
      </div>
    `
}

const tokensToRemove = [CookieKeys.tokenType, CookieKeys.expiresIn, CookieKeys.accessToken, CookieKeys.authorizedKey]

// Saga to handle authentication failure
export function handleAuthFailure() {
  SweetAlert.fire(options)
    .then(result => {
      if (result.isConfirmed) {
        window.location.pathname = Routes.LOGIN
      }
    })
    .catch(error => {
      console.warn('err=>', error)
    })
}

export function redirectToLogin() {
  handleAuthFailure()
}

// Helper function to remove cookies and handle authentication failure
export function handleApiError(statusCode) {
  if (statusCode === STATUS.PRECONDITION_FAILED) {
    removeCookies(tokensToRemove)
    redirectToLogin()
  }
}
