import * as actionTypes from '../constants/actionTypes'

const initialState = {
  loadingUserStatic: true,
  userDashboardStatic: false,
  userStaticError: false,

  loadingMyShipmentStatic: true,
  myShipmentStatic: false,
  muShipmentStaticError: false,

  loadingMyStorageStatic: true,
  myStorageStatic: {},
  myStorageStaticError: false,

  loadingIncOrder: true,
  incOrders: false,
  incOrderError: false,

  loadingSsOrder: true,
  ssOrders: false,
  ssOrderError: false,

  loading_PL: false,
  PL_Error: false,

  loadingOrderDetails: true,
  orderDetails: false,
  orderDetailsError: false,

  loadingOrderCancel: false,
  cancelOrderId: false,
  cancelOrderSuccessMsg: false,
  cancelOrderError: false,

  SH_Orders: false,
  loading_SH_Order: true,
  SH_OrderError: false
}

const userPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    //
    case actionTypes.GET_USER_ORDER_STATISTIC_START:
      return {
        ...state,
        userDashboardStatic: false,
        loadingUserStatic: true,
        userStaticError: false
      }
    case actionTypes.GET_USER_ORDER_STATISTIC_SUCCESS:
      return {
        ...state,
        userDashboardStatic: action.data,
        userStaticError: false,
        loadingUserStatic: false
      }
    case actionTypes.GET_USER_ORDER_STATISTIC_FAILED:
      return {
        ...state,
        userDashboardStatic: false,
        userStaticError: action.data,
        loadingUserStatic: false
      }

    // my shipment statistic
    case actionTypes.GET_MY_SHIPMENT_ORDER_STATISTIC_START:
      return {
        ...state,
        myShipmentStatic: false,
        loadingMyShipmentStatic: true,
        muShipmentStaticError: false
      }
    case actionTypes.GET_MY_SHIPMENT_ORDER_STATISTIC_SUCCESS:
      return {
        ...state,
        myShipmentStatic: action.data,
        muShipmentStaticError: false,
        loadingMyShipmentStatic: false
      }
    case actionTypes.GET_MY_SHIPMENT_ORDER_STATISTIC_FAILED:
      return {
        ...state,
        myShipmentStatic: false,
        muShipmentStaticError: action.data,
        loadingMyShipmentStatic: false
      }

    // my storage statistic
    case actionTypes.GET_MY_STORAGE_ORDER_STATISTIC_START:
      return {
        ...state,
        myStorageStatic: false,
        loadingMyStorageStatic: true,
        myStorageStaticError: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_STATISTIC_SUCCESS:
      return {
        ...state,
        myStorageStatic: action.data,
        myStorageStaticError: false,
        loadingMyStorageStatic: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_STATISTIC_FAILED:
      return {
        ...state,
        myStorageStatic: false,
        myStorageStaticError: action.data,
        loadingMyStorageStatic: false
      }

    // get incomplete orders by status
    case actionTypes.GET_INCOMPLETE_ORDER_START:
      return {
        ...state,
        incOrders: false,
        loadingIncOrder: true,
        incOrderError: false
      }
    case actionTypes.GET_INCOMPLETE_ORDER_SUCCESS:
      return {
        ...state,
        incOrders: action.data,
        incOrderError: false,
        loadingIncOrder: false
      }
    case actionTypes.GET_INCOMPLETE_ORDER_FAILED:
      return {
        ...state,
        incOrders: false,
        incOrderError: action.data,
        loadingIncOrder: false
      }

    // get ss orders by status
    case actionTypes.GET_SS_ORDER_BY_STATUS_START:
      return {
        ...state,
        ssOrders: false,
        loadingSsOrder: true,
        ssOrderError: false
      }
    case actionTypes.GET_SS_ORDER_BY_STATUS_SUCCESS:
      return {
        ...state,
        ssOrders: action.data,
        ssOrderError: false,
        loadingSsOrder: false
      }
    case actionTypes.GET_SS_ORDER_BY_STATUS_FAILED:
      return {
        ...state,
        ssOrders: false,
        ssOrderError: action.data,
        loadingSsOrder: false
      }

    // get Print label
    case actionTypes.GET_DOWNLOAD_PRINT_LABEL_START:
      return {
        ...state,
        loading_PL: true,
        PL_Error: false
      }
    case actionTypes.GET_DOWNLOAD_PRINT_LABEL_SUCCESS:
      return {
        ...state,
        loading_PL: false,
        PL_Error: false
      }
    case actionTypes.GET_DOWNLOAD_PRINT_LABEL_FAILED:
      return {
        ...state,
        loading_PL: false,
        PL_Error: action.data
      }
    case actionTypes.GET_DOWNLOAD_PRINT_LABEL_END:
      return {
        ...state,
        loading_PL: false,
        PL_Error: false
      }

    // get shipment history orders by status
    case actionTypes.GET_SHIPMENT_HISTORY_ORDERS_START:
      return {
        ...state,
        SH_Orders: false,
        loading_SH_Order: true,
        SH_OrderError: false
      }
    case actionTypes.GET_SHIPMENT_HISTORY_ORDERS_SUCCESS:
      return {
        ...state,
        SH_Orders: action.data,
        SH_OrderError: false,
        loading_SH_Order: false
      }
    case actionTypes.GET_SHIPMENT_HISTORY_ORDERS_FAILED:
      return {
        ...state,
        SH_Orders: false,
        SH_OrderError: action.data,
        loading_SH_Order: false
      }

    // order details
    case actionTypes.GET_ORDER_DETAILS_START:
      return {
        ...state,
        orderDetails: false,
        loadingOrderDetails: true,
        orderDetailsError: false
      }
    case actionTypes.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: action.data,
        orderDetailsError: false,
        loadingOrderDetails: false
      }
    case actionTypes.GET_ORDER_DETAILS_FAILED:
      return {
        ...state,
        orderDetails: false,
        orderDetailsError: action.data,
        loadingOrderDetails: false
      }

    // order cancel
    case actionTypes.PUT_CANCEL_ORDER_RESET:
      return {
        ...state,
        loadingOrderCancel: false,
        cancelOrderId: false,
        cancelOrderError: false,
        cancelOrderSuccessMsg: false
      }
    case actionTypes.PUT_CANCEL_ORDER_START:
      console.log({ action })

      return {
        ...state,
        loadingOrderCancel: true,
        cancelOrderId: action.data,
        cancelOrderError: false
      }
    case actionTypes.PUT_CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loadingOrderCancel: false,
        cancelOrderId: false,
        cancelOrderError: false,
        cancelOrderSuccessMsg: action.data
      }
    case actionTypes.PUT_CANCEL_ORDER_FAILED:
      return {
        ...state,
        loadingOrderCancel: false,
        cancelOrderId: false,
        cancelOrderError: action.data
      }

    default:
      return state
  }
}

export default userPanelReducer
