import React, { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { cn } from '@/lib/utils'
import { assets } from '@/helpers/common'
import useResponsive from '@/hooks/responsive'
import MenuDashboard from '@/components/subComponents/menu/MenuDashboard'
import MobileMenuDashboard from '@/components/subComponents/menu/MobileMenuDashboard'
import MobileAuthMenuDashboard from '@/components/subComponents/menu/MobileAuthMenuDashboard'
import MainLogo from '~/public/images/logos/shipandstorage.svg'
import Image from 'next/image'

const menu = [
  { title: 'Dashboard', dashboardIcon: true, icon: 'images/dashboard/icon/dashboard.png', link: '/dashboard' },
  {
    title: 'My Shipment',
    dashboardIcon: true,
    icon: 'images/dashboard/icon/my-shipment-small.png',
    link: '/my-shipment'
  },
  {
    title: 'My Storage',
    dashboardIcon: true,
    icon: 'images/dashboard/icon/my-storage-small.png',
    link: '/my-storage'
  },
  {
    title: 'My Reward',
    dashboardIcon: true,
    icon: 'images/dashboard/icon/my-reward.png',
    link: '/my-reward',
    disabled: true
  },
  {
    title: 'My Profile',
    dashboardIcon: true,
    icon: 'images/dashboard/icon/my-profile.png',
    link: '/my-profile',
    disabled: true
  }
]

const mobileMenu = [
  { title: 'Dashboard', dashboardIcon: true, icon: 'images/dashboard/mobile-icons/dashboard.png', link: '/dashboard' },
  { title: 'Shipment', dashboardIcon: true, icon: 'images/dashboard/mobile-icons/shipment.png', link: '/my-shipment' },
  {
    title: 'Storage',
    dashboardIcon: true,
    icon: 'images/dashboard/mobile-icons/storage.png',
    link: '/my-storage'
  },
  {
    title: 'Reward',
    dashboardIcon: true,
    icon: 'images/dashboard/mobile-icons/reward.png',
    link: '/my-reward',
    disabled: true
  },
  {
    title: 'Profile',
    dashboardIcon: true,
    icon: 'images/dashboard/mobile-icons/profile.png',
    link: '/my-profile',
    disabled: true
  }
]

const AuthHeader = props => {
  const router = useRouter()
  const { xs, sm, md, lg, xl, xxl } = useResponsive()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90
  })

  const isInHomePage = router.asPath === '/'

  const showMobileMenu = false
  const loginMenu = [{ type: 'userInfo' }, { type: 'logout' }]

  useEffect(() => {
    let bodyEl = document.querySelector('body')
    if (showMobileMenu) {
      bodyEl.style.overflow = 'hidden'
    } else {
      bodyEl.style.overflow = 'auto'
    }
  }, [showMobileMenu])

  return (
    <>
      <header
        id='header-main'
        className={cn(
          `site-header bg-[#EAF3F9] sticky top-0 right-0 left-0 z-50 transition xs:duration-200 min-h-[8.75rem] xs:min-h-[11.25rem] xl:!min-h-[98px] flex items-center`,
          props.className,
          // { 'with-banner white-ice-two fixed': isInHomePage },
          { 'with-banner white-ice-two': isInHomePage },
          { 'bg-gray-100': trigger }
        )}
      >
        <div className='container mx-auto '>
          <nav className='navbar row w-full justify-between items-center'>
            <Link className='navebar-brand user-mobile-brand' href='/'>
              <div className={'max-w-[120px] xl:max-w-[160px]'}>
                <Image priority src={MainLogo} alt={'Ship And Storage'} width={163} height={74} />
              </div>
            </Link>
            {lg || xl || xxl ? (
              <MenuDashboard menuItems={menu} loginMenu={loginMenu} />
            ) : (
              <MobileAuthMenuDashboard menuItems={menu} loginMenu={loginMenu} />
            )}
            {xs || sm || md ? <MobileMenuDashboard menuItems={mobileMenu} loginMenu={loginMenu} /> : ''}
          </nav>
        </div>
        {props.children}
      </header>
    </>
  )
}

export default AuthHeader
