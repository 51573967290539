import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faList, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

import ActiveLink from '@/components/common/ActiveLink'
import { assets } from '@/helpers/common'
import parse from 'html-react-parser'

library.add(faList, faTimes, faChevronDown, faChevronUp)

const MenuItem = function (props) {
  const [subMenuStatus, setsubMenuStatus] = useState(false)

  // change Sub Menu Status
  const changeSubMenuStatus = () => {
    setsubMenuStatus(!subMenuStatus)
  }

  // get Sub Menu Status
  const getSubMenuStatus = () => {
    return window.innerWidth < 992 ? subMenuStatus : true
  }

  // collece sub menu
  let submenuItems = false
  if (props.item.childrens && props.item.childrens.length > 0) {
    submenuItems = props.item.childrens.map((item, key) => {
      return <MenuItem key={key} item={item} changeMenuStatus={props.changeMenuStatus} />
    })
  }

  function getMenuIconTitle(item) {
    if (item.icon && props.icon) {
      return (
        <ActiveLink
          href={item.link}
          onClick={props.changeMenuStatus}
          className={item.type === 'btn' ? 'btn btn-primary' : ''}
        >
          <span className='icon'>
            <img src={assets(item.icon)} alt={''} />
          </span>
          <span>{item?.title ? parse(item.title) : ''}</span>
        </ActiveLink>
      )
    } else {
      return (
        <ActiveLink
          href={item.link}
          onClick={props.changeMenuStatus}
          className={item.type === 'btn' ? 'btn btn-primary' : ''}
        >
          <span>{item?.title ? parse(item.title) : ''}</span>
        </ActiveLink>
      )
    }
  }

  let className = props.item.className ? props.item.className : ''

  return (
    <li className={className}>
      {getMenuIconTitle(props.item)}
      {submenuItems ? (
        <span className='submenu-togle' onClick={changeSubMenuStatus}>
          {getSubMenuStatus() ? (
            <span className='submenu-close'>
              <FontAwesomeIcon icon={['fas', 'chevron-up']} /> DD
            </span>
          ) : (
            <span className='submenu-open'>
              <FontAwesomeIcon icon={['fas', 'chevron-down']} /> fas
            </span>
          )}
        </span>
      ) : (
        ''
      )}
      {submenuItems ? <ul className='submenu'>{submenuItems}</ul> : ''}
    </li>
  )
}

export default MenuItem
