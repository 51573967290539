import Configs from '@/configs'
import * as types from '@/store/constants/actionTypes'
import axios from 'axios'
import router from 'next/router'
import { put } from 'redux-saga/effects'
import { getEncryptedCookie } from '@/v1/lib/cookie'
import CookieKeys from '@/configs/caching/keys/cookie'
import { handleApiError, redirectToLogin } from '@/store/sagas/handle-api-error'

// GET ALL  countries
export function* getAllCountriesSaga(action) {
  try {
    const countries = yield localStorage.getItem('countries')

    yield put({ type: types.FETCH_COUNTRIES_START })

    if (countries && countries.length > 0) {
      yield put({ type: types.FETCH_COUNTRIES_SUCCESS, data: JSON.parse(countries) })
    } else {
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL
      })

      let result = yield instance
        .get('countrys', action.creds)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield localStorage.setItem('countries', JSON.stringify(result.data.data))
        yield put({ type: types.FETCH_COUNTRIES_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.FETCH_COUNTRIES_FAILED, data: result.response.data })
      }
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET ALL  states
export function* getAllStatesSaga(action) {
  try {
    yield put({ type: types.FETCH_STATES_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })
    let url = 'states'
    if (action.creds.country) {
      url = url + '?country=' + action.creds.country
    }
    let result = yield instance
      .get(url, action.creds)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.FETCH_STATES_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.FETCH_STATES_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET ALL  states for payment
export function* getAllPaymentStatesSaga(action) {
  try {
    yield put({ type: types.PAYMENT_STATES_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })
    let url = 'states'
    if (action.creds.country) {
      url = url + '?country=' + action.creds.country
    }
    let result = yield instance
      .get(url, action.creds)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.PAYMENT_STATES_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.PAYMENT_STATES_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// GET ALL  states for payment
export function* getAllReceiverStatesSaga(action) {
  try {
    yield put({ type: types.RECEIVER_STATES_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })
    let url = 'states'
    if (action.creds.country) {
      url = url + '?country=' + action.creds.country
    }
    let result = yield instance
      .get(url, action.creds)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.RECEIVER_STATES_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.RECEIVER_STATES_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET ALL  Warehouses
export function* getAllWarehouseSaga() {
  try {
    yield put({ type: types.FETCH_WAREHOUSE_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .get('order/warehouses')
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.FETCH_WAREHOUSE_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.FETCH_WAREHOUSE_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET ALL  Price List
export function* getPriceListSaga(action) {
  try {
    yield put({ type: types.GET_PRICE_LIST_START })
    const instance = yield axios.create({
      // baseURL: 'https://api.shipandstorage.com/api/',
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .post('order/price/check', action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200 && result.data.status) {
      yield put({ type: types.GET_PRICE_LIST_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.GET_PRICE_LIST_FAILED, data: result.response.data })
    }
    // yield put({ type: types.GET_PRICE_LIST_END });
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET Edit  Order
export function* getEditOrderSaga(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (token) {
      yield put({ type: types.GET_EDIT_ORDER_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('order/' + action.data.order_id)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_EDIT_ORDER_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_EDIT_ORDER_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_EDIT_ORDER_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET ALL  Price List
export function* getOrderInfoSaga(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (token) {
      yield put({ type: types.GET_ORDER_INFO_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('order/get-order-processing-details/' + action.id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_ORDER_INFO_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.GET_PRICE_LIST_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_PRICE_LIST_END })
    } else {
      redirectToLogin()
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET sender origin address
export function* getSenderReceiverSaga(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (token) {
      yield put({ type: types.GET_SENDER_ORIGIN_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('order/get-sender-and-origin-address/' + action.id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_SENDER_ORIGIN_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.GET_PRICE_LIST_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_SENDER_ORIGIN_END })
    } else {
      redirectToLogin()
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// GET fedex store list by zip
export function* fedexStoreList(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.FEDEX_STORE_LIST_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .get('order/get-drop-off-location-info/' + action.order_id + '/zipcode/' + action.zip_code, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.FEDEX_STORE_LIST_SUCCESS, data: result.data.data })
    } else {
      yield put({ type: types.FEDEX_STORE_LIST_FAILED, data: result.response.data })
    }
    yield put({ type: types.FEDEX_STORE_LIST_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
    yield put({ type: types.FEDEX_STORE_LIST_END })
    yield put({ type: types.FEDEX_STORE_LIST_FAILED })
  }
}

// Set sender origin address
export function* setSenderOrigininfo(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.SET_SENDER_ORIGIN_ADDR_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .put('order/update-sender-and-origin-address/' + action.id, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.SET_SENDER_ORIGIN_ADDR_SUCC, data: result.data })
    } else {
      yield put({ type: types.SET_SENDER_ORIGIN_ADDR_FAIL, data: result.response.data })
    }
    yield put({ type: types.SET_SENDER_ORIGIN_ADDR_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// payment request
export function* sendPayment_req(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  // if (!token) {
  //   if (indexOf([], action.path) > -1) {
  //     yield put(router.push(action.path))
  //   } else {
  //     yield put(router.push('/login'))
  //   }
  // }

  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.SEND_PAYMENT_API_REQ_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .put('order/update-payment-info/' + action.id, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.SEND_PAYMENT_API_REQ_SUCC, data: result.data })
    } else {
      yield put({ type: types.SEND_PAYMENT_API_REQ_FAIL, data: result.response.data })
    }
    yield put({ type: types.SEND_PAYMENT_API_REQ_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// payment request
export function* promocode_apply(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.PROMO_CODE_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .get('order/discount/apply/' + action.order_id + '/promo/' + action.promo, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.PROMO_CODE_SUCC, data: result.data })
    } else {
      yield put({ type: types.PROMO_CODE_FAIL, data: result.response.data })
    }
    yield put({ type: types.PROMO_CODE_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// submit order
export function* submit_order(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.SUBMIT_ORDER_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .put('order/submit-order/' + action.id, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.SUBMIT_ORDER_SUCCESS, data: result.data })
    } else {
      yield put({ type: types.SUBMIT_ORDER_FAILED, data: result.response.data })
    }
    yield put({ type: types.SUBMIT_ORDER_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET insurance info
export function* getInsuranceInfo(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (!token) {
      redirectToLogin()
    }

    if (token) {
      yield put({ type: types.GET_INSURANCE_INFO_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('order/get-insurance-info/' + action.order_id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_INSURANCE_INFO_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.GET_INSURANCE_INFO_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_INSURANCE_INFO_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// reset insurance
export function* reset_insurance(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.RESET_INSURANCE_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .put('order/reset-insurance-info/' + action.order_id, action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.RESET_INSURANCE_SUCCESS, data: result.data })
    } else {
      yield put({ type: types.RESET_INSURANCE_FAILED, data: result.response.data })
    }
    yield put({ type: types.RESET_INSURANCE_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// submit insurance
export function* submit_insurance(action) {
  const token = yield getEncryptedCookie(CookieKeys.accessToken)
  if (!token) {
    redirectToLogin()
  }

  try {
    yield put({ type: types.SUBMIT_INSURANCE_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL,
      headers: { Authorization: 'Bearer ' + token }
    })

    let result = yield instance
      .put('order/update-insurance-info', action.data)
      .then(response => response)
      .catch(err => {
        handleApiError(err.response.status)

        return err
      })

    if (result.status === 200) {
      yield put({ type: types.SUBMIT_INSURANCE_SUCCESS, data: result.data })
    } else {
      yield put({ type: types.SUBMIT_INSURANCE_FAILED, data: result.response.data })
    }
    yield put({ type: types.SUBMIT_INSURANCE_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// book now order
export function* bookNowSaga(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (!token) {
      redirectToLogin()
    } else {
      yield put({ type: types.BOOK_NOW_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .post('/order/create', action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200 && result.data.status) {
        // console.log(Buffer.from(result.data.data.id).toString('base64'));
        // let url = '/order-processing/' + encrypt(result.data.data.id);
        // decrypt("Y2F0ZWdvcnk9dGV4dGlsZSZ1c2VyPXVzZXIx") // for decode
        // yield put({ type: types.GET_EDIT_ORDER_END});
        yield put({ type: types.BOOK_NOW_SUCCESS, data: result.data })
        sessionStorage.removeItem('parent_items')
        // yield put(router.push(url));
      } else {
        yield put({ type: types.BOOK_NOW_FAILED })

        // redirectToLogin()
      }
      yield put({ type: types.BOOK_NOW_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// get label delivery info
export function* get_label_delivery(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (!token) {
      redirectToLogin()
    }

    if (token) {
      yield put({ type: types.GET_LABEL_INFO_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get('order/get-delivery-label-info/' + action.order_id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_LABEL_INFO_SUCCESS, data: result.data.data })
      } else {
        yield put({ type: types.GET_LABEL_INFO_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_LABEL_INFO_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// get label delivery info
export function* update_label_delivery(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (!token) {
      redirectToLogin()
    }

    if (token) {
      yield put({ type: types.SET_LABEL_INFO_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .put('order/update-delivery-label-info/' + action.order_id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.SET_LABEL_INFO_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.SET_LABEL_INFO_FAILED, data: result.response.data })
      }
      yield put({ type: types.SET_LABEL_INFO_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// Update RECEIVER info
export function* update_receiver(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (!token) {
      redirectToLogin()
    }

    if (token) {
      yield put({ type: types.SET_RECEIVER_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .put('order/update-receiver-and-destination-address/' + action.order_id, action.data)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.SET_RECEIVER_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.SET_RECEIVER_FAILED, data: result.response.data })
      }
      yield put({ type: types.SET_RECEIVER_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
// Update RECEIVER info
export function* getTrackingInfoSaga(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)

    if (!token) {
      redirectToLogin()
    }

    if (token) {
      yield put({ type: types.GET_TRACKING_INFO_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      let result = yield instance
        .get(
          'order/get-tracking-info/' + action.order_id + '/' + action.luggage_id + '/' + action.tracking,
          action.data
        )
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_TRACKING_INFO_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_TRACKING_INFO_FAILED, data: result.response.data })
      }
      yield put({ type: types.GET_TRACKING_INFO_END })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
