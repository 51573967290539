import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import parse from 'html-react-parser'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Generate tailwind content with parser
export function parsedContent(html: string, classes: ClassValue = ''): { html: string; classes: ClassValue } {
  return { html: (!!html ? parse(html) : '') as string, classes: cn('parsed-content-styles', classes) }
}

// Rounded Number => 1.555 => 1.56 ()
export function rn(input: string | number): string {
  const numberValue = typeof input === 'string' ? parseFloat(input) : Number(input)

  if (isNaN(numberValue)) {
    return ''
  }

  // Round to two decimal places and use toFixed to ensure it always displays two decimal places.
  const roundedValue = Math.round(numberValue * 100) / 100

  return roundedValue.toFixed(2)
}

// 2 Decimal places
export function nf2(input: string | number): string {
  return Number(input).toFixed(2)
}
