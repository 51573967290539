import axios from 'axios'
import Configs from '@/configs'
import { put } from 'redux-saga/effects'
import { getEncryptedCookie } from '@/v1/lib/cookie'
import * as types from '@/store/constants/actionTypes'
import CookieKeys from '@/configs/caching/keys/cookie'
import { handleApiError, redirectToLogin } from '@/store/sagas/handle-api-error'

// GET my storage order
export function* getMyStorageOrders(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_MY_STORAGE_ORDER_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let url = '/user-dashboard/get-my-storage-data?page=' + action.data.page // action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_STORAGE_ORDER_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_MY_STORAGE_ORDER_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_STORAGE_ORDER_FAILED })
    }
    yield put({ type: types.GET_MY_STORAGE_ORDER_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my storage In Progress order
export function* getMyStorageInProgressOrders(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_MY_STORAGE_IN_PROGRESS_ORDER_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let url = '/user-dashboard/get-my-storage-in-progress-data?page=' + action.data.page // action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_STORAGE_IN_PROGRESS_ORDER_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_MY_STORAGE_IN_PROGRESS_ORDER_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_STORAGE_IN_PROGRESS_ORDER_FAILED })
    }
    yield put({ type: types.GET_MY_STORAGE_IN_PROGRESS_ORDER_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my storage In Progress order
export function* getMyStorageHistoryOrders(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_MY_STORAGE_HISTORY_ORDERS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let url = '/user-dashboard/get-item-history?page=' + action.data.page // action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_STORAGE_HISTORY_ORDERS_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_MY_STORAGE_HISTORY_ORDERS_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_STORAGE_HISTORY_ORDERS_FAILED })
    }
    yield put({ type: types.GET_MY_STORAGE_HISTORY_ORDERS_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET my storage order details
export function* getMyStorageOrdersDetails(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_MY_STORAGE_ORDER_DETAILS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let url = '/user-dashboard/get-my-storage-data-detail/' + action.data.status + '/' + action.data.id // action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_MY_STORAGE_ORDER_DETAILS_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_MY_STORAGE_ORDER_DETAILS_FAILED })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_MY_STORAGE_ORDER_DETAILS_FAILED })
    }
    yield put({ type: types.GET_MY_STORAGE_ORDER_DETAILS_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// GET SHIP and PICKUP details

export function* getShipAndPickupDetails(action) {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token && action.data) {
      yield put({ type: types.GET_SHIP_AND_PICKUP_ORDER_DETAILS_START })
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      // let url = '/user-dashboard/ship-or-pickup?id=1,3,13,12,11'
      let url = '/user-dashboard/ship-or-pickup?id=' + action.data
      let result = yield instance
        .get(url)
        .then(response => response)
        .catch(err => {
          handleApiError(err.response.status)

          return err
        })

      if (result.status === 200) {
        yield put({ type: types.GET_SHIP_AND_PICKUP_ORDER_DETAILS_SUCCESS, data: result.data })
      } else {
        yield put({ type: types.GET_SHIP_AND_PICKUP_ORDER_DETAILS_END })
      }
    } else {
      redirectToLogin()
      yield put({ type: types.GET_SHIP_AND_PICKUP_ORDER_DETAILS_FAILED })
    }
    yield put({ type: types.GET_SHIP_AND_PICKUP_ORDER_DETAILS_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
