import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isTrackingModalOpen: false,
  trackingNumbers: [],
  dueAmount: {
    boxType: '',
    itemFees: {},
    trackingNumber: '',
    isDueAmountModalOpen: false
  }
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    handleTrackingModal: (state, action) => {
      state.isTrackingModalOpen = action.payload.open
      state.trackingNumbers = action.payload?.trackingNumbers || []
    },
    handleDueAmountModal: (state, action) => {
      state.dueAmount = {
        ...state.dueAmount,
        isDueAmountModalOpen: action.payload.open,
        boxType: action.payload?.boxType || '',
        itemFees: action.payload?.itemFees || {},
        trackingNumber: action.payload?.trackingNumber || ''
      }
    }
  }
})

export const { handleTrackingModal, handleDueAmountModal } = modalSlice.actions
export default modalSlice.reducer
