// that will be use later
export const HOME = '/'
export const BOOK_NOW = '/book-storage-facility'
export const BOOK_STORAGE_FACILITY = BOOK_NOW
export const CHECK_PRICE = '/check-price'
export const LOGIN = '/login'
export const REGISTER = '/register'
export const DASHBOARD = '/dashboard'

export const MY_STORAGE = '/my-storage'
