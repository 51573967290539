const Routes = {
  BOOK_NOW: '/book-storage-facility',
  BOOK_STORAGE_FACILITY: '/book-storage-facility',
  CHECK_PRICE: '/check-price',
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  MY_STORAGE: '/my-storage',
  CUSTOM_DOCUMENTS: '/order/custom-documents',
  TERMS: '/terms-and-conditions',
  FAQ: '/luggage-and-question',
  PROHIBITED_ITEMS: '/what-cant-ship',
  LUGGAGE_DROP_OFF_LOCATION: '/luggage-drop-off-location',
  LUGGAGE_TRACKING: '/luggage-tracking',
  SHIPPING_WEIGHT: '/shipping-weight',
  SHIPPING_TIPS: '/shipping-tips'
}

export type RoutesType = typeof Routes

export { Routes }
