import React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

ActiveLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any
}

function ActiveLink({ children, href, className = '', onClick, ...props }) {
  const router = useRouter()
  const activeCLass = router.isReady ? (className || '') + (router.asPath === href ? ` active` : '') : ''

  const handleClick = e => {
    e.preventDefault()
    router.push(href)
  }

  return (
    <a href={href} onClick={onClick || handleClick} className={activeCLass} {...props}>
      {children}
    </a>
  )
}

export default ActiveLink
