import CryptoJS from 'crypto-js'

type EncryptedCookieValue = string | object

const secret = process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY || 'your-secret-key' // Replace with your own secret key

const setCookie = (cName: string, cValue: any, maxAge: number) => {
  const today = new Date()
  // today.setTime(today.getTime() + maxAge * 1000)
  today.setTime(today.getTime() + maxAge * 1000 * 10000)

  const whenWillExpire = 'expires=' + today.toUTCString()
  let secure = false
  const sameSite = 'strict'

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    secure = true
  }

  document.cookie = `${cName}=${cValue}; ${whenWillExpire}; secure=${secure}; sameSite: ${sameSite}`
}

const getCookie = (cName: string) => {
  const name = cName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const numberOfCookies = decodedCookie.split(';')

  for (let i = 0; i < numberOfCookies.length; i++) {
    let eachCookie = numberOfCookies[i]
    while (eachCookie.charAt(0) === ' ') {
      eachCookie = eachCookie.substring(1)
    }
    if (eachCookie.indexOf(name) === 0) {
      return eachCookie.substring(name.length, eachCookie.length)
    }
  }

  return ''
}

const removeCookies = (cookies: string[]) => {
  cookies.forEach(name => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/`
  })
}

function setEncryptedCookie(cName: string, value: EncryptedCookieValue, maxAge = 86400) {
  const stringValue = JSON.stringify(value)
  const encryptedValue = CryptoJS.AES.encrypt(stringValue, secret).toString()

  const today = new Date()
  today.setTime(today.getTime() + maxAge * 1000)
  // today.setTime(today.getTime() + maxAge * 1000 * 10000)

  const whenWillExpire = 'expires=' + today.toUTCString()
  let secure = false
  const sameSite = 'None'

  if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
    secure = true
  }

  document.cookie = `${cName}=${encryptedValue}; ${whenWillExpire}; secure=${secure}; sameSite: ${sameSite}; Path=/`
}

function getEncryptedCookie(name: string): EncryptedCookieValue | null {
  const value = getMatchedCookie(name)
  if (value === null) {
    return null
  }

  try {
    const bytes = CryptoJS.AES.decrypt(value, secret)
    const decryptedValue = bytes.toString(CryptoJS.enc.Utf8)

    return JSON.parse(decryptedValue)
  } catch {
    return value
  }
}

function getMatchedCookie(name: string) {
  const cookieMatch = document.cookie.match(`(^|;)\\s*${name}=([^;]+)`)

  return cookieMatch ? cookieMatch[2] : null
}

const clearCookies = () => {
  return document.cookie
    .split(';')
    .forEach(
      cookie =>
        (document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
    )
}

export { setCookie, getCookie, removeCookies, setEncryptedCookie, getEncryptedCookie, getMatchedCookie, clearCookies }
