export const nodeENV = process.env.NEXT_PUBLIC_NODE_ENV || 'development'

// IF you want to apply versioning later on
/*const mapServer: Record<string, string> = {
  local: `${process.env.NEXT_PUBLIC_API_LOCAL_URL}/${process.env.NEXT_PUBLIC_API_LOCAL_VERSION}`,
  stage: `${process.env.NEXT_PUBLIC_API_STAGE_URL}/${process.env.NEXT_PUBLIC_API_STAGE_VERSION}`,
  development: `${process.env.NEXT_PUBLIC_API_DEV_URL}/${process.env.NEXT_PUBLIC_API_DEV_VERSION}`,
  production: `${process.env.NEXT_PUBLIC_API_PROD_URL}/${process.env.NEXT_PUBLIC_API_PROD_VERSION}`
}*/

const mapServer: Record<string, string> = {
  local: `${process.env.NEXT_PUBLIC_API_LOCAL_URL}`,
  development: `${process.env.NEXT_PUBLIC_API_DEV_URL}`,
  stage: `${process.env.NEXT_PUBLIC_API_STAGE_URL}`,
  production: `${process.env.NEXT_PUBLIC_API_PROD_URL}`
}

export const server = mapServer[nodeENV]

export const appUrl = `${process.env.NEXT_PUBLIC_APP_URL}`
