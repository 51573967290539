// @flow
import React, { SyntheticEvent } from 'react'
import Link from 'next/link'
import { Routes } from '@/v1/constants/routes'
import PageLoader from '@/components/common/PageLoader'
import { KeyValuePairs } from '@/types/global'
import LinkButton from '@/v1/components/ui/button/LinkButton'

type Props = {
  Auth_Reducer?: {
    authInfo?: KeyValuePairs
    auth?: KeyValuePairs
    checkAuth?: boolean
  }
  logout: (event: SyntheticEvent) => void
}
export default function UserMenu(props: Props) {
  const auth = props?.Auth_Reducer?.auth
  const checkAuth = props?.Auth_Reducer?.checkAuth
  const username = props?.Auth_Reducer?.authInfo?.first_name

  return (
    <>
      {auth ? (
        <div className='user-menu'>
          <button type='button' className='btn btn-primary capitalize'>
            {username || 'customer'}
          </button>
          <ul className={'flex gap-2'}>
            <li>
              <Link href={Routes.DASHBOARD}>Dashboard</Link>
            </li>
            <li>
              <Link href={Routes.LOGIN} onClick={props.logout}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      ) : checkAuth ? (
        <PageLoader />
      ) : (
        <>
          <div className={'flex gap-5 mb-1'}>
            <LinkButton href={Routes.LOGIN} aria-label={'Login'}>
              Login
            </LinkButton>
            <LinkButton href={Routes.REGISTER} className='bg-[#FF6D0A] hover:bg-[#FF6D0A]/90' aria-label={'Sign Up'}>
              Sign Up
            </LinkButton>
          </div>
        </>
      )}
    </>
  )
}
