import { useRouter } from 'next/router'
import Link from 'next/link'
import { cn } from '@/lib/utils'

export function FooterActiveLink({ href, target, className = '', children, ...rest }) {
  const router = useRouter()

  const pathname = router.asPath

  return (
    <Link
      {...rest}
      href={href}
      target={target}
      className={cn(
        'text-gray-dark-slate hover:text-orange-primary focus:text-orange-primary active:text-orange-primary',
        className,
        { 'text-orange-primary': pathname !== '/' && pathname === href }
      )}
    >
      {children}
    </Link>
  )
}
