import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { useRouter } from 'next/router'

import { HOME } from '@/router/allUrls'
import { RESET_CHECK_PRICE_DATA, USER_LOGOUT } from '@/store/constants/actionTypes'

const MobileAuthMenuDashboard = function (props) {
  const router = useRouter()
  const logout = async () => {
    await props.onLogout()
  }

  const removeCachedCheckPriceData = e => {
    e.preventDefault()
    props.onResetCheckPriceData()
    router
      .push({
        pathname: HOME,
        query: {
          ...router.query,
          new_order: true
        }
      })
      .then()
  }

  // get Auth Menu
  const getAuthItems = () => {
    // let authInfo = props.Auth.authInfo
    return (
      <ul className='main-menu user-main-menu mobile-auth-menu'>
        <li className='auth-btns'>
          <Link className='btn btn-primary new-order' href={HOME} onClick={removeCachedCheckPriceData}>
            Start New Order
          </Link>
          <button type='button' className='btn btn-primary logout' onClick={logout}>
            Logout
          </button>
        </li>
      </ul>
    )
  }

  return getAuthItems()
}

const mapStateToProps = state => {
  return {
    Auth: state.authReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: USER_LOGOUT }),
  onResetCheckPriceData: () => dispatch({ type: RESET_CHECK_PRICE_DATA })
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileAuthMenuDashboard)
