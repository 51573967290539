import axios from 'axios'
import router from 'next/router'
import { put } from 'redux-saga/effects'

import Configs from '@/configs'
import * as types from '@/store/constants/actionTypes'
import { getEncryptedCookie, removeCookies, setEncryptedCookie } from '@/v1/lib/cookie'
import CookieKeys from '@/configs/caching/keys/cookie'

const tokensToRemove = [CookieKeys.tokenType, CookieKeys.expiresIn, CookieKeys.accessToken, CookieKeys.authorizedKey]

// Register new user
export function* userRegisterSaga(action) {
  try {
    yield put({ type: types.USER_REGISTER_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .post('register', action.creds)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200) {
      setEncryptedCookie(CookieKeys.accessToken, result.data.access_token)
      setEncryptedCookie(CookieKeys.tokenType, result.data.token_type)
      setEncryptedCookie(CookieKeys.expiresIn, result.data.expires_in)
      yield put({ type: types.USER_REGISTER_SUCCESS, data: result.data })
      yield put({ type: types.USER_REGISTER_END })
    } else {
      yield put({ type: types.USER_REGISTER_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// Login new user
export function* userLoginSaga(action) {
  try {
    yield put({ type: types.USER_LOGIN_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .post('login', action.creds)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200) {
      setEncryptedCookie(CookieKeys.accessToken, result.data.access_token)
      setEncryptedCookie(CookieKeys.tokenType, result.data.token_type)
      setEncryptedCookie(CookieKeys.expiresIn, result.data.expires_in)

      yield put({ type: types.USER_LOGIN_SUCCESS, data: result.data })
      yield put({ type: types.USER_LOGIN_END })
      // yield put(router.push('/'));
    } else if (result.response.status === 422) {
      yield put({ type: types.USER_LOGIN_FAILED, data: result.response.data.errors })
    } else {
      yield put({ type: types.USER_LOGIN_FAILED, data: result.response.data })
    }
  } catch (error) {
    // yield put({ type: types.HEADER_ERROR, error });
  }
}

// Logout user
export function* userLogOutSaga() {
  try {
    // const token = yield localStorage.getItem('token')
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token) {
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })

      yield put({ type: types.USER_LOGOUT_START })

      yield instance
        .post('/auth/logout')
        .then(response => response)
        .catch(err => err)

      removeCookies(tokensToRemove)
      yield put({ type: types.USER_LOGOUT_SUCCESS })
      yield put(router.push('/login'))
    } else {
      yield put({ type: types.USER_LOGOUT_FAILED })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// Check Auth User
export function* checkAuthUserSaga() {
  try {
    const token = yield getEncryptedCookie(CookieKeys.accessToken)
    if (token) {
      const instance = yield axios.create({
        baseURL: Configs.apiBaseURL,
        headers: { Authorization: 'Bearer ' + token }
      })
      let result = yield instance
        .get('/auth/user-profile')
        .then(response => response)
        .catch(err => err)

      if (result.status === 200 && result.data.status) {
        yield put({ type: types.SET_AUTH_USER, data: result.data.data })
      } else {
        removeCookies(tokensToRemove)
        yield put({ type: types.AUTH_FAILED })
      }
    } else {
      yield put({ type: types.AUTH_FAILED })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// Reset Password
export function* resetPasswordSaga(action) {
  try {
    yield put({ type: types.RESET_PASSWORD_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })

    let result = yield instance
      .post('reset-password-request', action.creds)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200) {
      yield put({ type: types.RESET_PASSWORD_SUCCESS, data: result.data })
      // yield put({ type: types.RESET_PASSWORD_END });
    } else {
      yield put({ type: types.RESET_PASSWORD_FAILED, data: result.response.data })
    }
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}

// change Password
export function* changePasswordSaga(action) {
  try {
    yield put({ type: types.CHANGE_PASSWORD_START })
    const instance = yield axios.create({
      baseURL: Configs.apiBaseURL
    })
    let data = action.creds
    let token = action.creds.token
    delete data.token

    let result = yield instance
      .post('change-password?token=' + token, action.creds)
      .then(response => response)
      .catch(err => err)

    if (result.status === 200 || result.status === 201) {
      yield put({ type: types.CHANGE_PASSWORD_SUCCESS, data: result.data })
    } else {
      yield put({ type: types.CHANGE_PASSWORD_FAILED, data: result.response.data })
    }
    yield put({ type: types.CHANGE_PASSWORD_END })
  } catch (error) {
    yield put({ type: types.HEADER_ERROR, error })
  }
}
