import React from 'react'
import Link from 'next/link'
import { cn } from '@/lib/utils'

type Props = {
  className?: string
  href: string
  text?: string | React.ReactNode
  children?: string | React.ReactNode
}

export default function LinkButton({ text, children, className, href, ...rest }: Props) {
  return (
    <>
      <Link
        href={href}
        className={cn(
          'min-w-[112px] h-[44px] flex items-center justify-center bg-[#0A9CFF] hover:bg-[#0A9CFF]/90 rounded-[6px] text-white font-bold',
          className
        )}
        {...rest}
      >
        {children ? children : text}
      </Link>
    </>
  )
}
