import * as actionTypes from '../constants/actionTypes'

const initialState = {
  isDetailsOpen: false,

  loadingMyStorage: true,
  myStorage: [],
  myStorageError: false,

  loadingMyStorageInProgress: true,
  myStorageInProgress: [],
  myStorageErrorInProgress: false,

  loadingMyStorageHistory: true,
  myStorageHistory: [],
  myStorageErrorHistory: false,

  myStorageDetails: [],
  loadingMyStorageDetails: true,
  myStorageDetailsError: false,

  shipAndPickupDetails: [],
  loadingShipAndPickupDetails: true,
  shipAndPickupDetailsError: false
}

const myStorageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_DETAILS_MODEL:
      return {
        ...state,
        isDetailsOpen: action.data.isOpen
      }

    // get my storage orders
    case actionTypes.GET_MY_STORAGE_ORDER_START:
      return {
        ...state,
        myStorage: [],
        loadingMyStorage: true,
        myStorageError: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_SUCCESS:
      return {
        ...state,
        myStorage: action.data,
        myStorageError: false,
        loadingMyStorage: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_FAILED:
      return {
        ...state,
        myStorage: false,
        myStorageError: action.data,
        loadingMyStorage: false
      }

    // get my storage in-progress orders

    case actionTypes.GET_MY_STORAGE_IN_PROGRESS_ORDER_START:
      return {
        ...state,
        myStorageInProgress: [],
        loadingMyStorageInProgress: true,
        myStorageErrorInProgress: false
      }
    case actionTypes.GET_MY_STORAGE_IN_PROGRESS_ORDER_SUCCESS:
      return {
        ...state,
        myStorageInProgress: action.data,
        myStorageErrorInProgress: false,
        loadingMyStorageInProgress: false
      }
    case actionTypes.GET_MY_STORAGE_IN_PROGRESS_ORDER_FAILED:
      return {
        ...state,
        myStorageInProgress: false,
        myStorageErrorInProgress: action.data,
        loadingMyStorageInProgress: false
      }
    // get my storage history orders

    case actionTypes.GET_MY_STORAGE_HISTORY_ORDERS_START:
      return {
        ...state,
        myStorageHistory: [],
        loadingMyStorageHistory: true,
        myStorageErrorHistory: false
      }
    case actionTypes.GET_MY_STORAGE_HISTORY_ORDERS_SUCCESS:
      return {
        ...state,
        myStorageHistory: action.data,
        myStorageErrorHistory: false,
        loadingMyStorageHistory: false
      }
    case actionTypes.GET_MY_STORAGE_HISTORY_ORDERS_FAILED:
      return {
        ...state,
        myStorageHistory: false,
        myStorageErrorHistory: action.data,
        loadingMyStorageHistory: false
      }

    // get my storage orders details
    case actionTypes.GET_MY_STORAGE_ORDER_DETAILS_START:
      return {
        ...state,
        myStorageDetails: [],
        loadingMyStorageDetails: true,
        myStorageDetailsError: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        myStorageDetails: action.data,
        myStorageDetailsError: false,
        loadingMyStorageDetails: false
      }
    case actionTypes.GET_MY_STORAGE_ORDER_DETAILS_FAILED:
      return {
        ...state,
        myStorageDetails: false,
        myStorageDetailsError: action.data,
        loadingMyStorageDetails: false
      }

    // get Shipment and pickup order details
    case actionTypes.GET_SHIP_AND_PICKUP_ORDER_DETAILS_START:
      return {
        ...state,
        shipAndPickupDetails: [],
        loadingShipAndPickupDetails: true,
        shipAndPickupDetailsError: false
      }
    case actionTypes.GET_SHIP_AND_PICKUP_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        shipAndPickupDetails: action.data,
        loadingShipAndPickupDetails: false,
        shipAndPickupDetailsError: false
      }
    case actionTypes.GET_SHIP_AND_PICKUP_ORDER_DETAILS_FAILED:
      return {
        ...state,
        shipAndPickupDetails: [],
        loadingShipAndPickupDetails: false,
        shipAndPickupDetailsError: action.data
      }

    default:
      return state
  }
}

export default myStorageReducer
